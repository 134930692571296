import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllIdeas, getAllIdeasByGoal, getProjectUsers, selectIdeas, updateSelectedIdea } from "../../../redux/slices/projectSlice";
import { backendServerBaseURL } from "../../../utils/backendServerBaseURL";
import { formatTime } from "../../../utils/formatTime";
import {
  hasPermission_create_ideas,
  hasPermission_share_ideas,
  isTypeOwner,
  isRoleAdmin,
  isRoleMember,
} from "../../../utils/permissions";
import TourModal from "../Tour/TourModal";
import CreateNewIdeaDialog from "./CreateNewIdeaDialog";
import DeleteIdeaDialog from "./DeleteIdeaDialog";
import GoalBasedIdea from "./GoalBasedIdea";
import ShareIdeaDialog from "./ShareIdeaDialog";
import TestIdeaDialog from "./TestIdeaDialog";
import { selectallGrowthLevers } from "../../../redux/slices/settingSlice";
import PromptDialog from "../AI/PromptDialog";
import AiIdeasPrompt from "../AI/AiIdeas";

function Ideas() {
  const [selectedMenu, setselectedMenu] = useState("All Ideas");
  const ideas = useSelector(selectIdeas);
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId;
  console.log("projectId IDEAS :>> ", projectId);
  const dispatch = useDispatch();
  const openedProject = JSON.parse(localStorage.getItem("openedProject", ""));
  const allGrowthLevers = useSelector(selectallGrowthLevers);
  console.log("allGrowthLevers Ideas :>> ", allGrowthLevers);
  let leversData = allGrowthLevers.map((x) => {
    return {
      name: x.name,
      color: x.color,
    };
  });
  console.log("leversData :>> ", leversData);

  const ProjectsMenus = [
    {
      name: "All Ideas",
    },
    {
      name: "Goal Based",
    },
  ];

  const RightProjectsMenus = [];
  const [isLoading, setIsLoading] = useState(true); // Loading state flag
  const [showSkeletonLoader, setShowSkeletonLoader] = useState(true);

  const [showPrompt, setShowPrompt] = useState(false);
  const [showAiIdeas, setShowAiIdeas] = useState(false);

  useEffect(() => {
    if (selectedMenu == "All Ideas") {
      dispatch(getAllIdeas({ projectId: projectId }));
    }

    if (selectedMenu == "Goal Based") {
      dispatch(getAllIdeasByGoal({ projectId }));
    }

    dispatch(getProjectUsers({ projectId }));
    setTimeout(() => {
      setShowSkeletonLoader(false);
    }, 2000);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [selectedMenu]);

  const showPromptDialog = () => {
    setShowPrompt(true);
  };
  const hidePromptDialog = () => {
    setShowPrompt(false);
  };
  const showIdeaPromptDialog = () => {
    setShowAiIdeas(true);
  };
  const hideGoalPromptDialog = () => {
    setShowAiIdeas(false);
  };

  const showThePrompt = async () => {
    // tldr fetch project
    let local_project = await localStorage.getItem("openedProject");
    if (local_project && JSON.parse(local_project).aiPrompt) {
      showIdeaPromptDialog();
    } else {
      showPromptDialog();
    }
  };

  return (
    <div>
      <div className="d-flex">
        <div>
          <h1 className="mb-1">{openedProject?.name}</h1>
          <p className="text-secondary">{ideas?.length == 1 ? `${ideas?.length} Idea` : `${ideas?.length} Ideas`}</p>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}>
            {hasPermission_create_ideas() && (
              <button
                type="button"
                className="btn border-primary text-primary"
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                onClick={() => {
                  showThePrompt();
                }}
                disabled={hasPermission_create_ideas() ? false : true}
              >
                <img src="/static/icons/ai-icon.svg" alt="ai-icon" /> Generate AI Ideas
              </button>
            )}
            {hasPermission_create_ideas() ? (
              <button
                type="button"
                className="btn btn-primary"
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                data-bs-toggle="modal"
                data-bs-target="#createNewIdeaDialog"
                onClick={() => {
                  dispatch(updateSelectedIdea(null));
                }}
                disabled={hasPermission_create_ideas() ? false : true}
              >
                + Suggest Ideas
              </button>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div className="flex-fill d-flex align-items-center">
          {ProjectsMenus?.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}

          <div className="flex-fill ml-auto"></div>

          {RightProjectsMenus?.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {ideas?.length === 0 && !isLoading ? (
        <div className="d-flex align-items-center justify-content-center mt-5">
          <div className="vstack gap-3 text-center">
            <img src="/static/illustrations/no-projects-found.svg" alt="" height="200px" />
            <h2 className="body1 regular-weight">No ideas created yet</h2>
            {selectedMenu === "All Projects" && (
              <div>
                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createNewIdeaDialog">
                  Suggest your first idea
                </button>
              </div>
            )}
          </div>
        </div>
      ) : ideas?.length !== 0 ? (
        <>
          <table
            className="table table-borderless mt-2 custom-table"
            style={selectedMenu === "All Ideas" ? { display: "inline-table" } : { display: "none" }}
          >
            <thead className="border-none">
              <tr>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Name
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Lever
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Goal
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Creator
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Created
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Nominations
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  ICE Score
                </td>
                <td scope="col" className="text-secondary body3 regular-weight"></td>
              </tr>
            </thead>
            <tbody>
              {ideas?.map((idea) => {
                return (
                  <tr
                    className="border bg-white custom-hover-effect"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      hasPermission_create_ideas() && dispatch(updateSelectedIdea(idea));
                      navigate(`/projects/${projectId}/ideas/${idea?._id}`);
                    }}
                  >
                    <td className="body3 regular-weight pb-0">
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title={idea?.name}
                        style={{
                          width: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                        }}
                      >
                        {idea.name}
                      </span>
                    </td>

                    <td className="body3 regular-weight pb-0">
                      {leversData.map((lever) => {
                        if (idea.lever === lever.name) {
                          let chipColor;
                          switch (lever.color) {
                            case "Blue":
                              chipColor = "blue-chip";
                              break;
                            case "Orange":
                              chipColor = "orange-chip";
                              break;
                            case "Green":
                              chipColor = "green-chip";
                              break;
                            case "Red":
                              chipColor = "red-chip";
                              break;
                            case "Yellow":
                              chipColor = "yellow-chip";
                              break;
                            default:
                              chipColor = "blue-chip";
                              break;
                          }
                          return (
                            <span className={chipColor} key={lever.name}>
                              {idea.lever}
                            </span>
                          );
                        }
                        return null;
                      })}
                    </td>

                    <td
                      className="body3 regular-weight pb-0"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={idea?.goal?.name}
                    >
                      <span
                        style={{
                          width: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                        }}
                      >
                        {idea?.goal?.name}
                      </span>
                    </td>
                    <td
                      className="body3 regular-weight pb-0 d-flex align-items-center"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={`${idea?.createdBy?.firstName} ${idea?.createdBy?.lastName}`}
                    >
                      <img
                        src={`${backendServerBaseURL}/${idea?.createdBy?.avatar}`}
                        alt=""
                        className="avatar3"
                        style={{ marginRight: "0.3rem" }}
                      />
                      <span
                        style={{
                          width: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {`${idea.createdBy ? [idea.createdBy.firstName, idea.createdBy.lastName].join(" ") : "Removed User"}`}
                      </span>
                    </td>
                    <td className="body3 regular-weight  pb-0">{formatTime(idea?.createdAt)}</td>
                    <td className="body3 regular-weight pb-0">{idea.nominations?.length}</td>
                    <td className="body3 regular-weight pb-0">{idea?.score}</td>
                    <td className="body3 regular-weight">
                      {hasPermission_create_ideas() ? (
                        <div class="dropdown">
                          <div
                            data-bs-toggle="dropdown"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <i
                              class="bi bi-three-dots-vertical cp custom-more-icon-hover-effect"
                              style={{ marginRight: "0.4rem", padding: "0.5rem" }}
                            ></i>
                          </div>

                          <ul
                            className="dropdown-menu"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <li className="border-bottom">
                              <a
                                className="dropdown-item body3 regular-weight"
                                onClick={() => {
                                  dispatch(updateSelectedIdea(idea));
                                  navigate(`/projects/${projectId}/ideas/${idea?._id}`);
                                }}
                              >
                                View Idea
                              </a>
                            </li>

                            <li className="border-bottom">
                              <a
                                className="dropdown-item body3 regular-weight"
                                data-bs-toggle="modal"
                                data-bs-target="#createNewIdeaDialog"
                                onClick={() => {
                                  dispatch(updateSelectedIdea(idea));
                                }}
                              >
                                Edit Idea
                              </a>
                            </li>

                            {hasPermission_share_ideas() && (
                              <li
                                className="border-bottom"
                                onClick={() => {
                                  dispatch(updateSelectedIdea(idea));
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#shareIdeaDialog"
                              >
                                <a className="dropdown-item body3 regular-weight" href="#">
                                  Share Idea
                                </a>
                              </li>
                            )}

                            <li
                              className="border-bottom"
                              onClick={() => {
                                dispatch(updateSelectedIdea(idea));
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#testIdeaModal"
                            >
                              <a className="dropdown-item body3 regular-weight" href="#">
                                Test Idea
                              </a>
                            </li>
                            <li
                              className="border-bottom"
                              onClick={() => {
                                dispatch(updateSelectedIdea(idea));
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#deleteIdeaDialog"
                            >
                              <a className="dropdown-item body3 regular-weight" href="#">
                                Delete Idea
                              </a>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        // Optional: You can return a loading spinner or fallback UI in case the conditions above are not met.
        <div>
          <table className="table table-borderless custom-table-sm">
            <thead className="border-none">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <tr style={{ width: "100%" }}>
                  <td className="text-secondary body3 regular-weight" style={{ width: "90px", maxWidth: "20%" }}>
                    Name
                  </td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td className="text-secondary body3 regular-weight" style={{ width: "100px", maxWidth: "20%" }}>
                    Lever
                  </td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td className="text-secondary body3 regular-weight" style={{ width: "100px", maxWidth: "20%" }}>
                    Goal
                  </td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td className="text-secondary body3 regular-weight" style={{ width: "10px", maxWidth: "20%" }}>
                    Creator
                  </td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td className="text-secondary body3 regular-weight" style={{ width: "100px", maxWidth: "20%" }}>
                    Created
                  </td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td className="text-secondary body3 regular-weight" style={{ width: "10px", maxWidth: "20%" }}>
                    Nominations
                  </td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td className="text-secondary body3 regular-weight" style={{ width: "10px", maxWidth: "20%" }}>
                    ICE Score
                  </td>
                </tr>
              </div>
            </thead>
            <tbody style={{ width: "100%" }}>
              <tr
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)",
                }}
              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)",
                }}
              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)",
                }}
              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)",
                }}
              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)",
                }}
              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)",
                }}
              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)",
                }}
              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)",
                }}
              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)",
                }}
              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                  </div>
                </div>
              </tr>
              <tr
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                  borderRadius: "4px",
                  border: "1px solid var(--black-400, #D6D6D6)",
                  background: "var(--color-gray-gray-100, #F3F4F6)",
                }}
              >
                <div className="skeleton-placeholder">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        className="body3 regular-weight align-middle"
                        style={{
                          width: "90px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "100px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                    <div>
                      <td
                        data-bs-placement="bottom"
                        style={{
                          width: "10px",
                          maxWidth: "20%",
                          borderRadius: "4px",
                          background: "#E5E7EB",
                          height: "5px !important",
                        }}
                      ></td>
                    </div>
                  </div>
                </div>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {/* {ideas?.length !== 0 ? (
        <table className="table table-borderless mt-2 custom-table">
          <thead className="border-none">
            <tr>
              <td scope="col" className="text-secondary body3 regular-weight">
                Name
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Lever
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Goal
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Creator
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Created
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Nominations
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                ICE Score
              </td>
              <td scope="col" className="text-secondary body3 regular-weight"></td>
            </tr>
          </thead>
          <tbody>
            {ideas?.map((idea) => {
              return (
                <tr
                  className="border bg-white custom-hover-effect"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    hasPermission_create_ideas() &&
                      dispatch(updateSelectedIdea(idea));
                    navigate(`/projects/${projectId}/ideas/${idea?._id}`);
                  }}
                >
                  <td className="body3 regular-weight pb-0">
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={idea?.name}
                      style={{
                        width: "150px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                      }}
                    >
                      {idea.name}
                    </span>
                  </td>
                 
                  <td className="body3 regular-weight pb-0">
                    {leversData.map((lever) => {
                      if (idea.lever === lever.name) {
                        let chipColor;
                        switch (lever.color) {
                          case "Blue":
                            chipColor = "blue-chip";
                            break;
                          case "Orange":
                            chipColor = "orange-chip";
                            break;
                          case "Green":
                            chipColor = "green-chip";
                            break;
                          case "Red":
                            chipColor = "red-chip";
                            break;
                          case "Yellow":
                            chipColor = "yellow-chip";
                            break;
                          default:
                            chipColor = "blue-chip";
                            break;
                        }
                        return (
                          <span className={chipColor} key={lever.name}>
                            {idea.lever}
                          </span>
                        );
                      }
                      return null;
                    })}
                  </td>

                  <td
                    className="body3 regular-weight pb-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={idea?.goal?.name}
                  >
                    <span
                      style={{
                        width: "150px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                      }}
                    >
                      {idea?.goal?.name}
                    </span>
                  </td>
                  <td
                    className="body3 regular-weight pb-0 d-flex align-items-center"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={`${idea?.createdBy?.firstName} ${idea?.createdBy?.lastName}`}
                  >
                    <img
                      src={`${backendServerBaseURL}/${idea?.createdBy?.avatar}`}
                      alt=""
                      className="avatar3"
                      style={{ marginRight: "0.3rem" }}
                    />
                    <span
                      style={{
                        width: "150px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {`${
                        idea.createdBy
                          ? [
                              idea.createdBy.firstName,
                              idea.createdBy.lastName,
                            ].join(" ")
                          : "Removed User"
                      }`}
                    </span>
                  </td>
                  <td className="body3 regular-weight  pb-0">
                    {formatTime(idea?.createdAt)}
                  </td>
                  <td className="body3 regular-weight pb-0">
                    {idea.nominations?.length}
                  </td>
                  <td className="body3 regular-weight pb-0">{idea?.score}</td>
                  <td className="body3 regular-weight">
                    {hasPermission_create_ideas() ? (
                      <div class="dropdown">
                        <div
                          data-bs-toggle="dropdown"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <i
                            class="bi bi-three-dots-vertical cp custom-more-icon-hover-effect"
                            style={{ marginRight: "0.4rem" }}
                          ></i>
                        </div>

                        <ul
                          className="dropdown-menu"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <li className="border-bottom">
                            <a
                              className="dropdown-item body3 regular-weight"
                              onClick={() => {
                                dispatch(updateSelectedIdea(idea));
                                navigate(
                                  `/projects/${projectId}/ideas/${idea?._id}`
                                );
                              }}
                            >
                              View Idea
                            </a>
                          </li>

                          <li className="border-bottom">
                            <a
                              className="dropdown-item body3 regular-weight"
                              data-bs-toggle="modal"
                              data-bs-target="#createNewIdeaDialog"
                              onClick={() => {
                                dispatch(updateSelectedIdea(idea));
                              }}
                            >
                              Edit Idea
                            </a>
                          </li>

                          {hasPermission_share_ideas() && (
                            <li
                              className="border-bottom"
                              onClick={() => {
                                dispatch(updateSelectedIdea(idea));
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#shareIdeaDialog"
                            >
                              <a
                                className="dropdown-item body3 regular-weight"
                                href="#"
                              >
                                Share Idea
                              </a>
                            </li>
                          )}

                          <li
                            className="border-bottom"
                            onClick={() => {
                              dispatch(updateSelectedIdea(idea));
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#testIdeaModal"
                          >
                            <a
                              className="dropdown-item body3 regular-weight"
                              href="#"
                            >
                              Test Idea
                            </a>
                          </li>
                          <li
                            className="border-bottom"
                            onClick={() => {
                              dispatch(updateSelectedIdea(idea));
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#deleteIdeaDialog"
                          >
                            <a
                              className="dropdown-item body3 regular-weight"
                              href="#"
                            >
                              Delete Idea
                            </a>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (isLoading &&
        <table className="table table-borderless custom-table-sm">
           <thead className="border-none">
           <div style={{ display: "flex", justifyContent: "space-between" }}>

          <tr style={{width: "100%"}}>
            <td className="text-secondary body3 regular-weight" style={{width: "90px",
                        maxWidth: "20%",}}>
              Name
            </td>
            </tr>
            <tr style={{width: "100%"}}>
            <td className="text-secondary body3 regular-weight" style={{  width: "100px",
                        maxWidth: "20%",}}>
              Lever
            </td>
            </tr>
            <tr style={{width: "100%"}}>
            <td className="text-secondary body3 regular-weight" style={{ width: "100px",
                        maxWidth: "20%",}}>
              Goal
            </td>
            </tr>
            <tr style={{width: "100%"}}>
            <td className="text-secondary body3 regular-weight" style={{  width: "10px",
                        maxWidth: "20%",}}>
              Creator
            </td>
            </tr>
            <tr style={{width: "100%"}}>
            <td  className="text-secondary body3 regular-weight" style={{  width: "100px",
                        maxWidth: "20%",}}>
              Created
            </td>
            </tr>
            <tr style={{width: "100%"}}>
            <td  className="text-secondary body3 regular-weight"style={{  width: "10px",
                        maxWidth: "20%",}}>
              Nominations
            </td>
          </tr>
          <tr style={{width: "100%"}}>
            <td  className="text-secondary body3 regular-weight"style={{  width: "10px",
                        maxWidth: "20%",}}>
              ICE Score	
            </td>
          </tr>
          </div>
        </thead>
          <tbody style={{width: "100%"}}>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
            <tr 
              style={{
                cursor: "pointer", marginTop: "10px", background: "#D6D6D6",
                border: "1px solid #C0C0C0"
              }}

            >
              <div className="skeleton-placeholder">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      className="body3 regular-weight align-middle"
                      style={{
                        width: "90px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "100px",
                        maxWidth: "20%",
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                  <div>
                    <td
                      data-bs-placement="bottom"
                      style={{
                        width: "10px",
                        maxWidth: "20%",                         
                        background: "#C0C0C0",
                        height: "5px !important",
                      }}
                    >
                    </td>
                  </div>
                </div>
              </div>
            </tr>
          </tbody>
        </table>       
       )} */}
      {selectedMenu === "Goal Based" && ideas?.length !== 0 && (
        <>
          <div className="hstack p-2 mt-3">
            <td style={{ minWidth: "20%", maxWidth: "20%" }} className="text-secondary body3 regular-weight"></td>
            <td style={{ minWidth: "10%", maxWidth: "10%" }} className="text-secondary body3 regular-weight">
              Lever
            </td>
            <td style={{ minWidth: "10%", maxWidth: "10%" }} className="text-secondary body3 regular-weight">
              Goal
            </td>
            <td style={{ minWidth: "20%", maxWidth: "20%" }} className="text-secondary body3 regular-weight">
              Creator
            </td>
            <td style={{ minWidth: "10%", maxWidth: "10%" }} className="text-secondary body3 regular-weight">
              Created
            </td>
            <td style={{ minWidth: "10%", maxWidth: "10%" }} className="text-secondary body3 regular-weight">
              Nominations
            </td>
            <td style={{ minWidth: "10%", maxWidth: "10%" }} className="text-secondary body3 regular-weight">
              ICE Score
            </td>
            <td style={{ minWidth: "10%", maxWidth: "10%" }} className="text-secondary body3 regular-weight"></td>
          </div>

          {selectedMenu === "Goal Based" &&
            ideas?.map((goal) => {
              return ideas.length !== 0 ? (
                <GoalBasedIdea goal={goal} />
              ) : (
                <table className="table table-borderless custom-table-sm">
                  <thead className="border-none">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <tr style={{ width: "100%" }}>
                        <td className="text-secondary body3 regular-weight" style={{ width: "90px", maxWidth: "20%" }}>
                          Name
                        </td>
                      </tr>
                      <tr style={{ width: "100%" }}>
                        <td className="text-secondary body3 regular-weight" style={{ width: "100px", maxWidth: "20%" }}>
                          Lever
                        </td>
                      </tr>
                      <tr style={{ width: "100%" }}>
                        <td className="text-secondary body3 regular-weight" style={{ width: "100px", maxWidth: "20%" }}>
                          Goal
                        </td>
                      </tr>
                      <tr style={{ width: "100%" }}>
                        <td className="text-secondary body3 regular-weight" style={{ width: "10px", maxWidth: "20%" }}>
                          Creator
                        </td>
                      </tr>
                      <tr style={{ width: "100%" }}>
                        <td className="text-secondary body3 regular-weight" style={{ width: "100px", maxWidth: "20%" }}>
                          Created
                        </td>
                      </tr>
                      <tr style={{ width: "100%" }}>
                        <td className="text-secondary body3 regular-weight" style={{ width: "10px", maxWidth: "20%" }}>
                          Nominations
                        </td>
                      </tr>
                      <tr style={{ width: "100%" }}>
                        <td className="text-secondary body3 regular-weight" style={{ width: "10px", maxWidth: "20%" }}>
                          ICE Score
                        </td>
                      </tr>
                    </div>
                  </thead>
                  <tbody style={{ width: "100%" }}>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                    <tr
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        background: "#D6D6D6",
                        border: "1px solid #C0C0C0",
                      }}
                    >
                      <div className="skeleton-placeholder">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              className="body3 regular-weight align-middle"
                              style={{
                                width: "90px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "100px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                          <div>
                            <td
                              data-bs-placement="bottom"
                              style={{
                                width: "10px",
                                maxWidth: "20%",
                                background: "#C0C0C0",
                                height: "5px !important",
                              }}
                            ></td>
                          </div>
                        </div>
                      </div>
                    </tr>
                  </tbody>
                </table>
              );
            })}
        </>
      )}
      <PromptDialog
        promptVisible={showPrompt}
        showNextPrompt={showIdeaPromptDialog}
        showPrompt={showPromptDialog}
        hidePrompt={hidePromptDialog}
      />
      <AiIdeasPrompt promptVisible={showAiIdeas} showPrompt={showIdeaPromptDialog} hidePrompt={hideGoalPromptDialog} />
      <CreateNewIdeaDialog />
      <TestIdeaDialog />
      <DeleteIdeaDialog />
      <TourModal />
      <ShareIdeaDialog />
    </div>
  );
}

export default Ideas;
