import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Form, FormikProvider } from "formik";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { backendServerBaseURL } from "../../../utils/backendServerBaseURL";
import axios from "../../../utils/axios";
import { Modal } from "react-bootstrap";

function PromptDialog({ hidePrompt, showPrompt, promptVisible, showNextPrompt }) {
  const params = useParams();
  const dispatch = useDispatch();
  const [isSubmitting, setisSubmitting] = useState(false);
  const [allValuesEntered, setValuesEntered] = useState(false);

  const baseFormik = useFormik({
    initialValues: {
      focus: "",
      objectives: "",
      activities: "",
      currentStage: "",
    },
    validationSchema: Yup.object().shape({
      focus: Yup.string().required("Primary focus description is required"),
      objectives: Yup.string().required("Objectives are required"),
      activities: Yup.string().required("Activities are required"),
      currentStage: Yup.string().required("Current stage is required"),
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setSubmitting(true);
      await createPrompt();
      setSubmitting(false);
    },
  });

  const { values, setFieldError, setFieldValue, touched, handleSubmit, getFieldProps, errors } = baseFormik;

  const createPrompt = async () => {
    let response = await axios.post(`${backendServerBaseURL}/api/v1/project/create-prompt/${params.projectId}`, values);

    if (response.status === 201 && response.data.message === "Prompt added to project successfully") {
      await localStorage.setItem("openedProject", JSON.stringify(response.data.project));
      hidePrompt();
      setTimeout(() => {
        showNextPrompt();
      }, 500);
    }
  };

  // useEffect(() => {
  //   let local = localStorage.getItem("openedProject");
  //   let project = JSON.parse(local);
  //   project.aiPrompt = null;
  //   localStorage.setItem("openedProject", JSON.stringify(project));
  // }, []);

  useEffect(() => {
    if (
      Object.keys(errors).length == 0 &&
      values.focus !== "" &&
      values.objectives !== "" &&
      values.activities !== "" &&
      values.currentStage !== ""
    ) {
      setValuesEntered(true);
    }
  }, [errors, values]);

  return (
    <Modal size="lg" show={promptVisible} centered onHide={() => hidePrompt(false)} aria-labelledby="goal-prompt">
      <Modal.Header closeButton>
        {/* <Modal.Title id="goal-prompt">
            Large Modal
          </Modal.Title> */}
        <img src="/static/icons/ai-icon.svg" alt="ai-icon" />
        <h3>AI Goals</h3>
      </Modal.Header>
      <Modal.Body>
        <p>Please answer a few brief questions to enable us to customize goals and ideas to your specific growth objectives.</p>

        {/* About Goal STEP */}
        <FormikProvider value={baseFormik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            {/* focus */}
            <div className="form-field">
              <label className="form-label">What is the primary focus of this project ?</label>
              <textarea
                type={"text"}
                {...getFieldProps("focus")}
                className="form-control form-control-lg"
                placeholder="Describe the main objectives or deliverables of the project."
              />
              <span
                className="invalid-feedback"
                style={{
                  display: Boolean(touched.focus && errors.focus) ? "block" : "none",
                }}
              >
                {errors.focus}
              </span>
            </div>
            {/* objecttives */}
            <div className="form-field">
              <label className="form-label">What are the top 1-3 objectives for this project in the upcoming quarter?</label>
              <textarea
                type={"text"}
                {...getFieldProps("objectives")}
                className="form-control form-control-lg"
                placeholder="List up to three key goals for the next quarter."
              />
              <span
                className="invalid-feedback"
                style={{
                  display: Boolean(touched.objectives && errors.objectives) ? "block" : "none",
                }}
              >
                {errors.objectives}
              </span>
            </div>
            {/* activities */}
            <div className="form-field">
              <label className="form-label">What activities are currently being implemented to achieve these objectives?</label>
              <textarea
                type={"text"}
                {...getFieldProps("activities")}
                className="form-control form-control-lg"
                placeholder="Detail the strategies or actions in place to meet your project goals."
              />
              <span
                className="invalid-feedback"
                style={{
                  display: Boolean(touched.activities && errors.activities) ? "block" : "none",
                }}
              >
                {errors.activities}
              </span>
            </div>
            {/* stage */}
            <div className="form-field">
              <label className="form-label">Company stage currently?</label>
              <select {...getFieldProps("currentStage")} className="form-select form-select-sm">
                <option value="">Select the current company stage.</option>
                <option value="Idea/Pre-launch">Idea/Pre-launch</option>
                <option value="Startup/Early-stage">Startup/Early-stage</option>
                <option value="Growth/Expansion stage">Growth/Expansion stage</option>
                <option value="Maturity/Established">Maturity/Established</option>
                <option value="Scaling">Scaling</option>
              </select>
              <span
                className="invalid-feedback"
                style={{
                  display: Boolean(touched.currentStage && errors.currentStage) ? "block" : "none",
                }}
              >
                {errors.currentStage}
              </span>
            </div>

            {/* Action Buttons */}
            <div className="hstack gap-2 d-flex justify-content-end">
              <button
                type="button"
                class="btn  btn-outline-danger"
                data-bs-dismiss="modal"
                onClick={() => {
                  // resetAllFields();
                }}
              >
                Cancel
              </button>

              <button
                type="submit"
                disabled={!allValuesEntered || isSubmitting}
                className={"btn " + (!allValuesEntered || isSubmitting ? "btn-secondary text-black" : "btn-primary")}
              >
                {isSubmitting ? "Generating Goals" : "Submit"}
              </button>
            </div>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
}

export default PromptDialog;
