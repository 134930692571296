// Calculate realised revenue (first output)
function calculate_realized_revenue_per_order(average_order_value, realisation_rate, time_to_collect, days) {
  let realized_revenue_per_order = [];
  for (let day = 1; day <= days; day++) {
    realized_revenue_per_order.push(average_order_value * realisation_rate);
  }
  return realized_revenue_per_order;
}

function calculate_cogs_per_order(average_order_value, cogs_percentage, time_to_collect, days) {
  let cogs_per_order = [];
  for (let day = 1; day <= days; day++) {
    cogs_per_order.push(average_order_value * cogs_percentage);
  }
  return cogs_per_order;
}

// MARKETING - OUTBOUND DATA

function calculate_sdr_salaries(outbound_salary, contacts_per_day_per_sdr, number_of_sdrs, days) {
  let sdr_salaries = [];
  for (let day = 1; day <= days; day++) {
    sdr_salaries.push(outbound_salary * number_of_sdrs);
  }
  return sdr_salaries;
}

function calculate_contacts(contacts_per_day_per_sdr, number_of_sdrs, days) {
  let contacts = [];
  for (let day = 1; day <= days; day++) {
    let total_contacts = contacts_per_day_per_sdr * number_of_sdrs;
    contacts.push(total_contacts);
  }
  return contacts;
}

function calculate_total_outbound_leads(contacts, conversion_rate, max_leads, time_to_market, projection_period_days) {
  let total_leads = 0;
  for (let day = 0; day < projection_period_days; day++) {
    if (day >= time_to_market) {
      let leads_that_day = Math.floor(contacts[day - time_to_market] * conversion_rate);
      total_leads += Math.min(leads_that_day, max_leads);
    }
  }
  return total_leads;
}

function calculate_outbound_leads_daily(contacts, conversion_rate, max_leads, time_to_market, days) {
  let outbound_leads = Array(days).fill(0); // Initialize the list of outbound leads for each day with 0
  for (let day = 0; day < days; day++) {
    if (day >= time_to_market) {
      let leads_that_day = Math.floor(contacts[day - time_to_market] * conversion_rate);
      outbound_leads[day] = Math.min(leads_that_day, max_leads);
    }
  }
  return outbound_leads;
}

function calculate_outbound_customers(
  daily_outbound_leads,
  lead_to_customer_conversion_rate_outbound,
  max_customers,
  time_to_sell,
  days
) {
  let outbound_customers = Array(days).fill(0); // Initialize the list of outbound customers for each day with 0
  for (let day = 0; day < days; day++) {
    if (day >= time_to_sell) {
      let customers_that_day = Math.floor(daily_outbound_leads[day - time_to_sell] * lead_to_customer_conversion_rate_outbound);
      outbound_customers[day] = Math.min(customers_that_day, max_customers);
    }
  }
  return outbound_customers;
}

// MAX REVENUE SHOULD BE ON TOTAL REVENUE INSTEAD OF OUTBOUND REVENUE, REMOVE FROM OUTBOUND REVENUE

function calculate_outbound_revenue(daily_customers, realized_revenue_per_order, max_revenue, time_to_collect, days) {
  let outbound_revenue = Array(days).fill(0); // Initialize the list of outbound revenue for each day with 0
  for (let day = 0; day < days; day++) {
    let index = day - time_to_collect;
    if (index >= 0) {
      let revenue_that_day = daily_customers[index] * (realized_revenue_per_order[index] || 0);
      outbound_revenue[day] = Math.min(revenue_that_day, max_revenue);
    }
  }
  return outbound_revenue;
}

function calculate_outbound_gross_profit(cogs_percentage, outbound_revenue, time_to_collect, days) {
  let outbound_gross_profit = [];
  for (let day = 0; day < days; day++) {
    if (day >= time_to_collect) {
      let outbound_gross_profit_that_day = outbound_revenue[day - time_to_collect] * (1 - cogs_percentage);
      outbound_gross_profit.push(outbound_gross_profit_that_day);
    }
  }
  return outbound_gross_profit;
}

// MARKETING - INBOUND DATA

function calculate_inbound_ad_spend(inbound_daily_ad_spend, projection_period_days) {
  let inbound_ad_spend = [];
  for (let day = 1; day <= projection_period_days; day++) {
    inbound_ad_spend.push(inbound_daily_ad_spend);
  }
  return inbound_ad_spend;
}

function calculate_inbound_reach(inbound_daily_ad_spend, inbound_cpm, projection_period_days) {
  let inbound_reach = [];
  for (let day = 1; day <= projection_period_days; day++) {
    if (inbound_cpm > 0) {
      let reach = (inbound_daily_ad_spend / inbound_cpm) * 1000;
      inbound_reach.push(Math.floor(reach));
    } else {
      inbound_reach.push(0);
    }
  }
  return inbound_reach;
}

function calculate_inbound_link_clicks(inbound_reach, inbound_ctr, projection_period_days) {
  let inbound_link_clicks = [];
  for (let day = 0; day < projection_period_days; day++) {
    let clicks = Math.floor(inbound_reach[day] * inbound_ctr);
    inbound_link_clicks.push(clicks);
  }
  return inbound_link_clicks;
}

function calculate_inbound_page_views(inbound_link_clicks, inbound_landing_page_view_rate, projection_period_days) {
  let inbound_page_views = [];
  for (let day = 0; day < projection_period_days; day++) {
    let page_views = Math.floor(inbound_link_clicks[day] * inbound_landing_page_view_rate);
    inbound_page_views.push(page_views);
  }
  return inbound_page_views;
}

function calculate_inbound_leads(inbound_page_views, inbound_lead_generation_rate, time_to_market, projection_period_days) {
  let inbound_leads = [];
  for (let day = 0; day < projection_period_days; day++) {
    let index = day - time_to_market;
    if (index >= 0) {
      let leads = Math.floor(inbound_page_views[index] * inbound_lead_generation_rate);
      inbound_leads.push(leads);
    } else {
      inbound_leads.push(0);
    }
  }
  return inbound_leads;
}

function calculate_inbound_customers(inbound_leads, inbound_conversion_rate, time_to_sell, projection_period_days) {
  let inbound_customers = [];
  for (let day = 0; day < projection_period_days; day++) {
    let index = day - time_to_sell;
    if (index >= 0) {
      let customers = Math.floor(inbound_leads[index] * inbound_conversion_rate);
      inbound_customers.push(customers);
    } else {
      inbound_customers.push(0);
    }
  }
  return inbound_customers;
}

function calculate_inbound_revenue(inbound_customers, realized_revenue_per_order, time_to_collect, projection_period_days) {
  let inbound_revenue = Array(projection_period_days).fill(0); // Initialize the list with zeros for each day
  for (let day = 0; day < projection_period_days; day++) {
    let index = day - time_to_collect;
    if (index >= 0) {
      let revenue_that_day = inbound_customers[index] * (realized_revenue_per_order[index] || 0);
      inbound_revenue[day] = revenue_that_day;
    }
  }
  return inbound_revenue;
}

function calculate_inbound_roas(inbound_revenue, inbound_ad_spend, projection_period_days) {
  let inbound_roas = Array(projection_period_days).fill(0); // Initialize the list with zeros for each day
  for (let day = 0; day < projection_period_days; day++) {
    if (inbound_ad_spend[day] > 0) {
      let roas = inbound_revenue[day] / inbound_ad_spend[day];
      inbound_roas[day] = roas;
    } else {
      inbound_roas[day] = 0; // Set ROAS to 0 if ad spend is zero to avoid division by zero
    }
  }
  return inbound_roas;
}

function calculate_inbound_gross_profit(inbound_revenue, cogs_percentage, projection_period_days) {
  let inbound_gross_profit = Array(projection_period_days).fill(0); // Initialize the list with zeros for each day
  for (let day = 0; day < projection_period_days; day++) {
    let gross_profit = inbound_revenue[day] * (1 - cogs_percentage);
    inbound_gross_profit[day] = gross_profit;
  }
  return inbound_gross_profit;
}

function calculate_inbound_roi(inbound_gross_profit, inbound_ad_spend, projection_period_days) {
  let inbound_roi = Array(projection_period_days).fill(0); // Initialize the list with zeros for each day
  for (let day = 0; day < projection_period_days; day++) {
    if (inbound_ad_spend[day] > 0) {
      let roi = inbound_gross_profit[day] / inbound_ad_spend[day];
      inbound_roi[day] = roi;
    } else {
      inbound_roi[day] = 0; // Set ROI to zero if ad spend is zero
    }
  }
  return inbound_roi;
}

// MARKETING - ORGANIC DATA

function calculate_organic_views(organic_views_per_day, projection_period_days) {
  return Array(projection_period_days).fill(organic_views_per_day);
}

function calculate_organic_leads(organic_views, organic_view_to_lead_conversion_rate, time_to_market, projection_period_days) {
  let organic_leads = Array(projection_period_days).fill(0); // Initialize the list of organic leads with zeros
  for (let day = 0; day < projection_period_days; day++) {
    if (day >= time_to_market) {
      let leads = Math.floor(organic_views[day - time_to_market] * organic_view_to_lead_conversion_rate);
      organic_leads[day] = leads;
    } else {
      organic_leads[day] = 0; // Days before 'time_to_market' have no leads
    }
  }
  return organic_leads;
}

function calculate_organic_customers(organic_leads, organic_lead_to_customer_conversion_rate, time_to_sell, projection_period_days) {
  let organic_customers = Array(projection_period_days).fill(0); // Initialize the list of organic customers with zeros
  for (let day = 0; day < projection_period_days; day++) {
    if (day >= time_to_sell) {
      let customers = Math.floor(organic_leads[day - time_to_sell] * organic_lead_to_customer_conversion_rate);
      organic_customers[day] = customers;
    } else {
      organic_customers[day] = 0; // Days before 'time_to_sell' have no customers
    }
  }
  return organic_customers;
}

function calculate_organic_revenue(organic_customers, realized_revenue_per_order, time_to_collect, projection_period_days) {
  let organic_revenue = Array(projection_period_days).fill(0); // Initialize the list of organic revenue with zeros
  for (let day = 0; day < projection_period_days; day++) {
    if (day >= time_to_collect) {
      let revenue = organic_customers[day - time_to_collect] * (realized_revenue_per_order[day - time_to_collect] || 0);
      organic_revenue[day] = revenue;
    } else {
      organic_revenue[day] = 0; // Days before 'time_to_collect' have no revenue
    }
  }
  return organic_revenue;
}

function calculate_organic_profit(organic_revenue, cogs_percentage, projection_period_days) {
  let organic_profit = Array(projection_period_days).fill(0); // Initialize the list of organic profits with zeros
  for (let day = 0; day < projection_period_days; day++) {
    let profit = organic_revenue[day] * (1 - cogs_percentage);
    organic_profit[day] = Math.floor(profit);
  }
  return organic_profit;
}

// MARKETING - TOTAL DATA FOR ACTIVITIES

function calculate_total_marketing_spend(inbound_ad_spend, sdr_salaries, projection_period_days) {
  let total_marketing_spend = Array(projection_period_days).fill(0); // Initialize the list of total marketing spends
  for (let day = 0; day < projection_period_days; day++) {
    total_marketing_spend[day] = inbound_ad_spend[day] + sdr_salaries[day];
  }
  return total_marketing_spend;
}

function calculate_total_marketing_customers_acquired(
  outbound_customers,
  inbound_customers,
  organic_customers,
  projection_period_days
) {
  let total_marketing_customers_acquired = Array(projection_period_days).fill(0); // Initialize the list of total marketing customers acquired
  for (let day = 0; day < projection_period_days; day++) {
    total_marketing_customers_acquired[day] = outbound_customers[day] + inbound_customers[day] + organic_customers[day];
  }
  return total_marketing_customers_acquired;
}

function calculate_total_marketing_revenue(outbound_revenue, inbound_revenue, organic_revenue, projection_period_days) {
  let total_marketing_revenue = Array(projection_period_days).fill(0); // Initialize the list for total marketing revenue
  for (let day = 0; day < projection_period_days; day++) {
    total_marketing_revenue[day] = outbound_revenue[day] + inbound_revenue[day] + organic_revenue[day];
  }
  return total_marketing_revenue;
}

function calculate_total_marketing_gross_profit(total_marketing_revenue, cogs_percentage) {
  return total_marketing_revenue.map((revenue) => revenue * (1 - cogs_percentage));
}

function calculate_marketing_roi(total_marketing_gross_profit, total_marketing_spend) {
  return total_marketing_gross_profit.map((profit, i) => (total_marketing_spend[i] > 0 ? profit / total_marketing_spend[i] : 0));
}

function calculate_total_customer_base(initial_customers, total_marketing_customers_acquired) {
  let total_customer_base = [initial_customers];
  for (let day = 1; day < total_marketing_customers_acquired.length; day++) {
    total_customer_base.push(total_customer_base[day - 1] + total_marketing_customers_acquired[day]);
  }
  return total_customer_base;
}

function calculate_returning_customers(total_customer_base, returning_customer_rate, time_to_return, projection_period_days) {
  let returning_customers = Array(projection_period_days).fill(0);
  for (let day = 0; day < projection_period_days; day++) {
    if (day >= time_to_return) {
      let previous_day_index = day - time_to_return;
      returning_customers[day] = Math.floor(total_customer_base[previous_day_index] * returning_customer_rate);
    }
  }
  return returning_customers;
}

function calculate_daily_returning_revenue(returning_customers, realized_revenue_per_order) {
  if (Array.isArray(realized_revenue_per_order)) {
    realized_revenue_per_order = realized_revenue_per_order[0] || 0;
  }
  return returning_customers.map((customers) => customers * realized_revenue_per_order);
}

function calculate_cost_to_market_return(daily_returning_revenues, cost_to_market_return_percentage) {
  return daily_returning_revenues.map((revenue) => revenue * cost_to_market_return_percentage);
}

function calculate_costs_of_refunds(average_order_value, fixed_loss_per_refund, projection_period_days) {
  return Array(projection_period_days).fill(average_order_value + fixed_loss_per_refund);
}

function calculate_daily_refund_orders(total_marketing_customers_acquired, returning_customers, refund_rate_percentage) {
  return total_marketing_customers_acquired.map((customers, day) =>
    Math.floor((customers + returning_customers[day]) * refund_rate_percentage)
  );
}

function calculate_daily_refunds_loss(daily_refund_orders, costs_of_refunds) {
  return daily_refund_orders.map((orders, day) => orders * costs_of_refunds[day]);
}

// MARKETING - VIRALITY METRICS

function calculate_daily_referrers(total_marketing_customers_acquired, referrers_out_of_customers_percentage) {
  return total_marketing_customers_acquired.map((customers) => Math.floor(customers * referrers_out_of_customers_percentage));
}

function calculate_total_invitees(daily_referrers, invitees_per_referral, time_to_market, projection_period_days) {
  let total_invitees = Array(projection_period_days).fill(0);
  for (let day = 0; day < projection_period_days; day++) {
    if (day >= time_to_market) {
      let referrer_index = day - time_to_market;
      total_invitees[day] = Math.floor(daily_referrers[referrer_index] * invitees_per_referral);
    }
  }
  return total_invitees;
}

function calculate_daily_referral_customers(total_invitees, invitees_conversion_rate, time_to_sell, projection_period_days) {
  let daily_referral_customers = Array(projection_period_days).fill(0);
  for (let day = 0; day < projection_period_days; day++) {
    if (day >= time_to_sell) {
      let invitee_index = day - time_to_sell;
      daily_referral_customers[day] = Math.floor(total_invitees[invitee_index] * invitees_conversion_rate);
    }
  }
  return daily_referral_customers;
}

function calculate_referral_revenue(daily_referral_customers, realized_revenue_per_order, time_to_collect, projection_period_days) {
  let referral_revenue = Array(projection_period_days).fill(0);
  for (let day = 0; day < projection_period_days; day++) {
    if (day >= time_to_collect) {
      let customer_index = day - time_to_collect;
      if (customer_index < daily_referral_customers.length) {
        referral_revenue[day] = daily_referral_customers[customer_index] * realized_revenue_per_order[customer_index];
      } else {
        referral_revenue[day] = 0;
      }
    }
  }
  return referral_revenue;
}

function calculate_total_gross_revenue(
  daily_outbound_revenue,
  inbound_revenue,
  organic_revenue,
  daily_returning_revenues,
  referral_revenue,
  daily_refunds_loss,
  projection_period_days
) {
  let total_gross_revenue = Array(projection_period_days).fill(0);
  for (let day = 0; day < projection_period_days; day++) {
    total_gross_revenue[day] =
      daily_outbound_revenue[day] +
      inbound_revenue[day] +
      organic_revenue[day] +
      daily_returning_revenues[day] +
      referral_revenue[day] -
      daily_refunds_loss[day];
  }
  return total_gross_revenue;
}

function calculate_lifetime_value_per_customer(total_gross_revenue, total_customer_base) {
  let lifetime_value_per_customer = [];
  for (let day = 0; day < total_gross_revenue.length; day++) {
    let lifetime_value =
      total_customer_base[day] > 0 ? total_gross_revenue.slice(0, day + 1).reduce((a, b) => a + b, 0) / total_customer_base[day] : 0;
    lifetime_value_per_customer.push(lifetime_value);
  }
  return lifetime_value_per_customer;
}

function calculate_total_gross_profit_before_adspend(total_gross_revenue, cogs_percentage) {
  return total_gross_revenue.map((revenue) => revenue - revenue * cogs_percentage);
}

function calculate_gross_profit_margin_before_adspend(total_gross_profit_before_adspend, total_gross_revenue) {
  return total_gross_profit_before_adspend.map((profit, i) => (total_gross_revenue[i] != 0 ? profit / total_gross_revenue[i] : 0));
}

function calculate_gross_profit_after_adspend(total_gross_revenue, total_marketing_spend, cogs_percentage) {
  return total_gross_revenue.map((revenue, i) => revenue - revenue * cogs_percentage - total_marketing_spend[i]);
}

function calculate_gross_profit_margin_after_adspend(gross_profit_after_adspend, total_gross_revenue) {
  return gross_profit_after_adspend.map((profit, i) => (total_gross_revenue[i] != 0 ? (profit / total_gross_revenue[i]) * 100 : 0));
}

function calculate_gross_profit_before_ads_per_customer_per_month(
  total_gross_profit_before_adspend,
  total_marketing_customers_acquired,
  returning_customers
) {
  return total_gross_profit_before_adspend.map((profit, i) => {
    let total_customers = total_marketing_customers_acquired[i] + returning_customers[i];
    return total_customers != 0 ? profit / total_customers : 0;
  });
}

function calculate_gross_profit_after_ads_per_customer_per_month(
  gross_profit_after_adspend,
  total_marketing_customers_acquired,
  returning_customers
) {
  let gross_profit_after_ads_per_customer_per_month = [];
  for (let day = 0; day < gross_profit_after_adspend.length; day++) {
    let total_customers = total_marketing_customers_acquired[day] + returning_customers[day];
    let profit_per_customer = total_customers > 0 ? gross_profit_after_adspend[day] / total_customers : 0;
    gross_profit_after_ads_per_customer_per_month.push(profit_per_customer);
  }
  return gross_profit_after_ads_per_customer_per_month;
}

function calculate_payment_processor_fees_per_order(
  average_order_value,
  realisation_rate,
  payment_processor_fees_percentage,
  projection_period_days
) {
  let payment_processor_fees_per_order = [];
  for (let day = 0; day < projection_period_days; day++) {
    payment_processor_fees_per_order.push(average_order_value * realisation_rate * payment_processor_fees_percentage);
  }
  return payment_processor_fees_per_order;
}

function calculate_merchant_account_fees_per_order(
  average_order_value,
  realisation_rate,
  merchant_account_fees_percentage,
  projection_period_days
) {
  let merchant_account_fees_per_order = [];
  for (let day = 0; day < projection_period_days; day++) {
    merchant_account_fees_per_order.push(average_order_value * realisation_rate * merchant_account_fees_percentage);
  }
  return merchant_account_fees_per_order;
}

function calculate_total_payment_processor_fees(
  total_marketing_customers_acquired,
  returning_customers,
  daily_referral_customers,
  payment_processor_fees_per_order
) {
  return total_marketing_customers_acquired.map((customers, day) => {
    let total_customers = customers + returning_customers[day] + daily_referral_customers[day];
    return total_customers * payment_processor_fees_per_order[day];
  });
}

function calculate_total_merchant_account_fees(
  total_marketing_customers_acquired,
  returning_customers,
  daily_referral_customers,
  merchant_account_fees_per_order
) {
  return total_marketing_customers_acquired.map((customers, day) => {
    let total_customers = customers + returning_customers[day] + daily_referral_customers[day];
    return total_customers * merchant_account_fees_per_order[day];
  });
}

function calculate_total_fixed_costs(total_customer_base, fixed_costs_per_day, fixed_costs_increase_per_100_customers_per_day) {
  return total_customer_base.map(
    (customers) => fixed_costs_per_day + (customers / 100) * fixed_costs_increase_per_100_customers_per_day
  );
}

function calculate_ebit(
  gross_profit_after_adspend,
  upfront_investment_costs,
  total_fixed_costs,
  total_merchant_account_fees,
  total_payment_processor_fees
) {
  return gross_profit_after_adspend.map((profit, day) => {
    return (
      profit - upfront_investment_costs - total_fixed_costs[day] - total_merchant_account_fees[day] - total_payment_processor_fees[day]
    );
  });
}

function calculate_cash_taxes(ebit, tax_rate_percentage) {
  return ebit.map((profit) => (profit > 0 ? profit * tax_rate_percentage : 0));
}

function calculate_daily_debt_payments(debt, debt_interest_rate_annual, projection_period_days) {
  let daily_interest_rate = debt_interest_rate_annual / 365;
  let daily_debt_payments = [];
  let remaining_debt = debt;
  let factor = (1 + daily_interest_rate) ** projection_period_days;
  let daily_payment = daily_interest_rate === 0 ? debt / projection_period_days : (debt * daily_interest_rate * factor) / (factor - 1);

  for (let day = 0; day < projection_period_days; day++) {
    if (remaining_debt <= 0) {
      daily_debt_payments.push(0);
    } else {
      let payment = Math.min(daily_payment, remaining_debt);
      daily_debt_payments.push(payment);
      remaining_debt -= payment;
    }
  }
  return daily_debt_payments;
}

function calculate_unlevered_free_cash_flow(ebit, cash_taxes, daily_debt_payments) {
  return ebit.map((profit, day) => profit - cash_taxes[day] - daily_debt_payments[day]);
}

function calculate_enterprise_value(daily_unlevered_free_cash_flow, discount_rate_percentage, projection_period_days) {
  let monthly_discount_rate = (1 + discount_rate_percentage) ** (1 / 12) - 1;
  return daily_unlevered_free_cash_flow.map((ufcf, day) => ufcf / (1 + monthly_discount_rate) ** (day + 1));
}

function calculate_earnings_after_interest_and_tax(ebit, daily_debt_payments, cash_taxes, projection_period_days) {
  let earnings_after_tax_and_interest = [];
  for (let day = 0; day < projection_period_days; day++) {
    let current_ebit = ebit[day] || 0;
    let current_interest = daily_debt_payments[day] || 0;
    let current_tax = cash_taxes[day] || 0;
    let net_earnings = current_ebit - current_interest - current_tax;
    earnings_after_tax_and_interest.push(net_earnings);
  }
  return earnings_after_tax_and_interest;
}

function calculate_daily_debt_balance(debt, daily_debt_payments) {
  let daily_debt_balance = [];
  let cumulative_payments = 0;
  for (let payment of daily_debt_payments) {
    cumulative_payments += payment;
    let current_balance = debt - cumulative_payments;
    daily_debt_balance.push(Math.max(0, current_balance));
  }
  return daily_debt_balance;
}

function calculate_cash_in_bank(initial_cash, earnings_after_tax_and_interest, projection_period_days) {
  let cash_in_bank = Array(projection_period_days).fill(0);
  if (projection_period_days > 0) {
    cash_in_bank[0] = initial_cash + earnings_after_tax_and_interest[0];
    for (let day = 1; day < projection_period_days; day++) {
      cash_in_bank[day] = cash_in_bank[day - 1] + earnings_after_tax_and_interest[day];
    }
  }
  return cash_in_bank;
}

function calculate_equity_value(enterprise_values, cash_in_bank, debts, projection_period_days) {
  let equity_values = Array(projection_period_days).fill(0);
  for (let day = 0; day < projection_period_days; day++) {
    equity_values[day] = enterprise_values[day] ? enterprise_values[day] + cash_in_bank[day] - debts[day] : 0;
  }
  return equity_values;
}

function calculate_equity_value_per_share(equity_values, number_of_shares, projection_period_days) {
  let equity_value_per_share = Array(projection_period_days).fill(0);
  for (let day = 0; day < projection_period_days; day++) {
    equity_value_per_share[day] = number_of_shares > 0 ? equity_values[day] / number_of_shares : 0;
  }
  return equity_value_per_share;
}

function calculate_average_terminal_value(enterprise_values, growth_rate, discount_rate, projection_period_days) {
  let average_terminal_value = Array(projection_period_days).fill(0);
  for (let day = 0; day < projection_period_days; day++) {
    if (discount_rate > growth_rate) {
      let terminal_value = (enterprise_values[day] * (1 + growth_rate)) / (discount_rate - growth_rate);
      average_terminal_value[day] = terminal_value;
    } else {
      average_terminal_value[day] = 0;
    }
  }
  return average_terminal_value;
}

function calculate_cash_roi_compared_to_day1(cash_in_bank, initial_cash, projection_period_days) {
  let cash_roi_compared_to_day1 = Array(projection_period_days).fill(0);
  if (initial_cash > 0) {
    for (let day = 0; day < projection_period_days; day++) {
      cash_roi_compared_to_day1[day] = cash_in_bank[day] / initial_cash;
    }
  }
  return cash_roi_compared_to_day1;
}

function calculate_cash_payback_period(cash_roi_compared_to_day1, projection_period_days) {
  let cash_payback_period = Array(projection_period_days).fill(false);
  for (let day = 0; day < projection_period_days; day++) {
    cash_payback_period[day] = cash_roi_compared_to_day1[day] >= 1;
  }
  return cash_payback_period;
}

function sumArray(arr) {
  let sum = 0;

  arr.forEach((item) => {
    if (!isNaN(item)) {
      sum += item;
    }
  });

  return sum;
}

export default function DCF({
  startDate,
  initial_cash,
  initial_customers,
  average_order_value,
  realisation_rate,
  units_per_order,
  cogs_percentage,
  outbound_salary,
  contacts_per_day_per_sdr,
  number_of_sdrs,
  contact_to_lead_conversion_rate,
  lead_to_customer_conversion_rate_outbound,
  inbound_daily_ad_spend,
  inbound_cpm,
  inbound_ctr,
  inbound_landing_page_view_rate,
  inbound_lead_generation_rate,
  inbound_conversion_rate,
  organic_views_per_day,
  organic_view_to_lead_conversion_rate,
  organic_lead_to_customer_conversion_rate,
  returning_customer_rate,
  time_to_return,
  cost_to_market_return_percentage,
  referrers_out_of_customers_percentage,
  invitees_per_referral,
  invitees_conversion_rate,
  refund_rate_percentage,
  fixed_loss_per_refund,
  payment_processor_fees_percentage,
  merchant_account_fees_percentage,
  fixed_costs_per_day,
  fixed_costs_increase_per_100_customers_per_day,
  upfront_investment_costs,
  debt,
  debt_interest_rate_annual,
  tax_rate_percentage,
  number_of_shares,
  projection_period_days,
  discount_rate_percentage,
  perpetual_growth_rate_percentage,
  inbound,
  outbound,
  organic,
  // new fields
  max_customers,
  max_revenue,
  average_revenue_per_customer,
  time_to_market,
  time_to_sell,
  time_to_collect,
  max_leads,
}) {
  let realized_revenue_per_order = null;
  let cogs_per_order = null;
  let sdr_salaries = null;
  let contacts = null;
  let total_outbound_leads = null;
  let daily_outbound_leads = null;
  let daily_outbound_customers = null;
  let daily_outbound_revenue = null;
  let outbound_gross_profit = null;
  let inbound_ad_spend = null;
  let inbound_reach = null;
  let inbound_link_clicks = null;
  let inbound_page_views = null;
  let inbound_leads = null;
  let inbound_customers = null;
  let inbound_revenue = null;
  let inbound_roas = null;
  let inbound_gross_profit = null;
  let inbound_roi = null;
  let organic_views = null;
  let organic_leads = null;
  let organic_customers = null;
  let organic_revenue = null;
  let organic_profit = null;
  let total_marketing_spend = null;
  let total_marketing_customers_acquired = null;
  let total_marketing_revenue = null;
  let total_marketing_gross_profit = null;
  let marketing_roi = null;
  let total_customer_base = null;
  let returning_customers = null;
  let daily_returning_revenue = null;
  let daily_cost_to_market_returns = null;
  let daily_costs_of_refunds = null;
  let daily_refunds = null;
  let daily_refunds_loss = null;
  let daily_referrers = null;
  let total_invitees = null;
  let daily_referral_customers = null;
  let referral_revenue = null;
  let total_gross_revenue = null;
  let lifetime_value_per_customer = null;
  let total_gross_profit_before_adspend = null;
  let gross_profit_margin_before_adspend = null;
  let gross_profit_after_adspend = null;
  let gross_profit_margin_after_adspend = null;
  let gross_profit_per_customer_per_month = null;
  let gross_profit_after_ads_per_customer_per_month = null;
  let payment_processor_fees_per_order = null;
  let merchant_account_fees_per_order = null;
  let total_payment_processor_fees = null;
  let total_merchant_account_fees = null;
  let total_fixed_costs = null;
  let ebit = null;
  let cash_taxes = null;
  let daily_debt_payments = null;
  let daily_unlevered_free_cash_flow = null;
  let daily_enterprise_value = null;
  let earnings_after_tax_interest = null;
  let daily_debt_balance = null;
  let cash_in_bank = null;
  let equity_values = null;
  let equity_value_per_share = null;
  let average_terminal_value = null;
  let cash_roi_compared_to_day1 = null;
  let cash_payback_period = null;
  let opVals = null;

  // Calculate realized revenue for each day
  realized_revenue_per_order = calculate_realized_revenue_per_order(
    average_order_value,
    realisation_rate,
    time_to_collect,
    projection_period_days
  );

  // Calculate COGS per order for each day
  cogs_per_order = calculate_cogs_per_order(average_order_value, cogs_percentage, time_to_collect, projection_period_days);

  // Calculate SDR salaries for each day
  sdr_salaries = calculate_sdr_salaries(outbound_salary, contacts_per_day_per_sdr, number_of_sdrs, projection_period_days);

  // Calculate contacts for each day
  contacts = calculate_contacts(contacts_per_day_per_sdr, number_of_sdrs, projection_period_days);

  // Calculate total outbound leads
  total_outbound_leads = calculate_total_outbound_leads(
    contacts,
    contact_to_lead_conversion_rate,
    max_leads,
    time_to_market,
    projection_period_days
  );

  // Calculate daily outbound leads
  daily_outbound_leads = calculate_outbound_leads_daily(
    contacts,
    contact_to_lead_conversion_rate,
    max_leads,
    time_to_market,
    projection_period_days
  );

  // Calculate daily outbound customers
  daily_outbound_customers = calculate_outbound_customers(
    daily_outbound_leads,
    lead_to_customer_conversion_rate_outbound,
    max_customers,
    time_to_sell,
    projection_period_days
  );

  // Calculate Outbound Revenue
  daily_outbound_revenue = calculate_outbound_revenue(
    daily_outbound_customers,
    realized_revenue_per_order,
    max_revenue,
    2,
    projection_period_days
  ); // time_to_collect is assumed to be 2 for example

  // Calculate outbound gross profit
  outbound_gross_profit = calculate_outbound_gross_profit(
    cogs_percentage,
    daily_outbound_revenue,
    time_to_collect,
    projection_period_days
  );

  // Calculate inbound ad spend for each day
  inbound_ad_spend = calculate_inbound_ad_spend(inbound_daily_ad_spend, projection_period_days);

  // Calculate inbound reach for each day
  inbound_reach = calculate_inbound_reach(inbound_daily_ad_spend, inbound_cpm, projection_period_days);

  // Calculate inbound link clicks for each day
  inbound_link_clicks = calculate_inbound_link_clicks(inbound_reach, inbound_ctr, projection_period_days);

  // Calculate inbound page views for each day
  inbound_page_views = calculate_inbound_page_views(inbound_link_clicks, inbound_landing_page_view_rate, projection_period_days);

  // Calculate inbound leads for each day
  inbound_leads = calculate_inbound_leads(inbound_page_views, inbound_lead_generation_rate, time_to_market, projection_period_days);

  // Calculate inbound customers for each day
  inbound_customers = calculate_inbound_customers(inbound_leads, inbound_conversion_rate, time_to_sell, projection_period_days);

  // Calculate inbound revenue for each day
  inbound_revenue = calculate_inbound_revenue(inbound_customers, realized_revenue_per_order, time_to_collect, projection_period_days);

  // Calculate inbound revenue
  inbound_revenue = calculate_inbound_revenue(inbound_customers, realized_revenue_per_order, time_to_collect, projection_period_days);

  // Calculate ROAS
  inbound_roas = calculate_inbound_roas(inbound_revenue, inbound_ad_spend, projection_period_days);

  // Calculate Inbound Gross Profit
  inbound_gross_profit = calculate_inbound_gross_profit(inbound_revenue, cogs_percentage, projection_period_days);

  // Calculate Inbound ROI
  inbound_roi = calculate_inbound_roi(inbound_gross_profit, inbound_ad_spend, projection_period_days);

  // Calculate Organic Views
  organic_views = calculate_organic_views(organic_views_per_day, projection_period_days);

  // Calculate Organic Leads
  organic_leads = calculate_organic_leads(organic_views, organic_view_to_lead_conversion_rate, time_to_market, projection_period_days);

  // Calculate Organic Customers
  organic_customers = calculate_organic_customers(
    organic_leads,
    organic_lead_to_customer_conversion_rate,
    time_to_sell,
    projection_period_days
  );

  // Calculate Organic Revenue
  organic_revenue = calculate_organic_revenue(organic_customers, realized_revenue_per_order, time_to_collect, projection_period_days);

  // Calculate Organic Profit
  organic_profit = calculate_organic_profit(organic_revenue, cogs_percentage, projection_period_days);

  // Calculate Total Marketing Spend
  total_marketing_spend = calculate_total_marketing_spend(inbound_ad_spend, sdr_salaries, projection_period_days);

  // Calculate Total Marketing Customers Acquired
  total_marketing_customers_acquired = calculate_total_marketing_customers_acquired(
    daily_outbound_customers,
    inbound_customers,
    organic_customers,
    projection_period_days
  );

  // Calculate Total Marketing Revenue
  total_marketing_revenue = calculate_total_marketing_revenue(
    daily_outbound_revenue,
    inbound_revenue,
    organic_revenue,
    projection_period_days
  );

  // Calculate Marketing Gross Profit
  total_marketing_gross_profit = calculate_total_marketing_gross_profit(total_marketing_revenue, cogs_percentage);

  // Calculate Marketing ROI
  marketing_roi = calculate_marketing_roi(total_marketing_gross_profit, total_marketing_spend);

  // Calculate Total Customer Base
  total_customer_base = calculate_total_customer_base(initial_customers, total_marketing_customers_acquired);

  // Calculate Returning Customers
  returning_customers = calculate_returning_customers(
    total_customer_base,
    returning_customer_rate,
    time_to_return,
    projection_period_days
  );

  // Calculate Monthly Returning Revenue
  daily_returning_revenue = calculate_daily_returning_revenue(returning_customers, realized_revenue_per_order);

  // Calculate Cost To Market Returns
  daily_cost_to_market_returns = calculate_cost_to_market_return(daily_returning_revenue, cost_to_market_return_percentage);

  // Calculate Cost per Refund
  daily_costs_of_refunds = calculate_costs_of_refunds(average_order_value, fixed_loss_per_refund, projection_period_days);

  // Calculate Daily Refunds
  daily_refunds = calculate_daily_refund_orders(total_marketing_customers_acquired, returning_customers, refund_rate_percentage);

  // Calculate Daily Refunds Loss
  daily_refunds_loss = calculate_daily_refunds_loss(daily_refunds, daily_costs_of_refunds);

  // Calculate Daily Referrers
  daily_referrers = calculate_daily_referrers(total_marketing_customers_acquired, referrers_out_of_customers_percentage);

  // Calculate total invitees
  total_invitees = calculate_total_invitees(daily_referrers, invitees_per_referral, time_to_market, projection_period_days);

  // Calculate daily referral customers
  daily_referral_customers = calculate_daily_referral_customers(
    total_invitees,
    invitees_conversion_rate,
    time_to_sell,
    projection_period_days
  );

  // Calculate referral revenue
  referral_revenue = calculate_referral_revenue(
    daily_referral_customers,
    realized_revenue_per_order,
    time_to_collect,
    projection_period_days
  );

  // Calculate Total Gross Revenue
  total_gross_revenue = calculate_total_gross_revenue(
    daily_outbound_revenue,
    inbound_revenue,
    organic_revenue,
    daily_returning_revenue,
    referral_revenue,
    daily_refunds_loss,
    projection_period_days
  );

  // Calculate Lifetime Value per Customer
  lifetime_value_per_customer = calculate_lifetime_value_per_customer(total_gross_revenue, total_customer_base);

  // Calculate Total Gross Profit before Ad Spend
  total_gross_profit_before_adspend = calculate_total_gross_profit_before_adspend(total_gross_revenue, cogs_percentage);

  // Calculate Gross Profit Margin before Ad Spend
  gross_profit_margin_before_adspend = calculate_gross_profit_margin_before_adspend(
    total_gross_profit_before_adspend,
    total_gross_revenue
  );

  // Calculate Gross Profit after Ad Spend
  gross_profit_after_adspend = calculate_gross_profit_after_adspend(total_gross_revenue, total_marketing_spend, cogs_percentage);

  // Calculate Gross Profit Margin after Ad Spend
  gross_profit_margin_after_adspend = calculate_gross_profit_margin_after_adspend(gross_profit_after_adspend, total_gross_revenue);

  // Calculate Gross Profit before Ads per Customer per Month
  gross_profit_per_customer_per_month = calculate_gross_profit_before_ads_per_customer_per_month(
    total_gross_profit_before_adspend,
    total_marketing_customers_acquired,
    returning_customers
  );

  // Calculate Gross Profit after Ads per Customer per Month
  gross_profit_after_ads_per_customer_per_month = calculate_gross_profit_after_ads_per_customer_per_month(
    gross_profit_after_adspend,
    total_marketing_customers_acquired,
    returning_customers
  );

  // Calculate Payment Processor Fees per Order
  payment_processor_fees_per_order = calculate_payment_processor_fees_per_order(
    average_order_value,
    realisation_rate,
    payment_processor_fees_percentage,
    projection_period_days
  );

  // Calculate Merchant Account Fees per Order
  merchant_account_fees_per_order = calculate_merchant_account_fees_per_order(
    average_order_value,
    realisation_rate,
    merchant_account_fees_percentage,
    projection_period_days
  );

  // Calculate Total Payment Processor Fees
  total_payment_processor_fees = calculate_total_payment_processor_fees(
    total_marketing_customers_acquired,
    returning_customers,
    daily_referral_customers,
    payment_processor_fees_per_order
  );

  // Calculate Total Merchant Account Fees
  total_merchant_account_fees = calculate_total_merchant_account_fees(
    total_marketing_customers_acquired,
    returning_customers,
    daily_referral_customers,
    merchant_account_fees_per_order
  );

  // Calculate Total Fixed Costs
  total_fixed_costs = calculate_total_fixed_costs(
    total_customer_base,
    fixed_costs_per_day,
    fixed_costs_increase_per_100_customers_per_day
  );

  // Calculate EBIT for each day
  ebit = calculate_ebit(
    gross_profit_after_adspend,
    upfront_investment_costs,
    total_fixed_costs,
    total_merchant_account_fees,
    total_payment_processor_fees
  );

  // Calculate cash taxes for each day
  cash_taxes = calculate_cash_taxes(ebit, tax_rate_percentage);

  // Calculate the daily debt interest payable
  daily_debt_payments = calculate_daily_debt_payments(debt, debt_interest_rate_annual, projection_period_days);

  // Calculate unlevered free cash flow
  daily_unlevered_free_cash_flow = calculate_unlevered_free_cash_flow(ebit, cash_taxes, daily_debt_payments);

  // Calculate Daily Enterprise Value
  daily_enterprise_value = calculate_enterprise_value(
    daily_unlevered_free_cash_flow,
    discount_rate_percentage,
    daily_unlevered_free_cash_flow.length
  );

  // Calculate Earnings After Interest and Tax
  earnings_after_tax_interest = calculate_earnings_after_interest_and_tax(
    ebit,
    daily_debt_payments,
    cash_taxes,
    projection_period_days
  );

  // Calculate the daily debt balance
  daily_debt_balance = calculate_daily_debt_balance(debt, daily_debt_payments);

  // Calculate the cash in bank for each day
  cash_in_bank = calculate_cash_in_bank(initial_cash, earnings_after_tax_interest, projection_period_days);

  // Calculate the equity value for each day
  equity_values = calculate_equity_value(daily_enterprise_value, cash_in_bank, daily_debt_balance, projection_period_days);

  // Calculate the equity value per share for each day
  equity_value_per_share = calculate_equity_value_per_share(equity_values, number_of_shares, projection_period_days);

  // Calculate the average terminal value for each day
  average_terminal_value = calculate_average_terminal_value(
    daily_enterprise_value,
    perpetual_growth_rate_percentage,
    discount_rate_percentage,
    projection_period_days
  );

  // Calculate the cash ROI compared to day 1
  cash_roi_compared_to_day1 = calculate_cash_roi_compared_to_day1(cash_in_bank, initial_cash, projection_period_days);

  // Calculate the cash payback period
  cash_payback_period = calculate_cash_payback_period(cash_roi_compared_to_day1, projection_period_days);

  // post-proccess values start

  let cashInBnkValIdx = cash_in_bank.findIndex((x) => x < 0);

  let totalGrossProfitIdx = gross_profit_after_adspend.findIndex((x) => x > 0);

  const cashROIformattedArr = cash_in_bank.map((value) => Number((value / 1000000).toFixed(2)));
  let cashInBnkValArrIdx = cashROIformattedArr.findIndex((x) => x > 1);

  let grossProfitPerCustResult = Array(lifetime_value_per_customer.length).fill(0);

  for (let i = 0; i < lifetime_value_per_customer.length; ++i) {
    grossProfitPerCustResult[i] = lifetime_value_per_customer[i] * (1 - cogs_percentage / 100);
  }

  let totalCashInBank = cash_in_bank[cash_in_bank.length - 2];

  let equityValTotal = equity_values.slice(-1)[0];

  let cashROIDayTotal = cash_roi_compared_to_day1.slice(-1)[0];

  let finalCashROICompToDayVal = initial_cash > 0 ? cashROIDayTotal : "False";

  let reachValArrResult2 = [];
  for (let i = 0; i < inbound_reach.length; i++) {
    reachValArrResult2[i] = Math.floor(inbound_reach[i]);
  }

  let linkClicksValArrResult2 = [];
  for (let i = 0; i < inbound_link_clicks.length; i++) {
    linkClicksValArrResult2[i] = Math.floor(inbound_link_clicks[i]);
  }

  let pageViewsValTwodecimalResult = [];
  for (let i = 0; i < inbound_page_views.length; i++) {
    pageViewsValTwodecimalResult[i] = Math.floor(inbound_page_views[i]);
  }

  let inboundCustomersValArrResult2 = [];
  for (let i = 0; i < inbound_customers.length; i++) {
    inboundCustomersValArrResult2[i] = Math.floor(inbound_customers[i]);
  }

  // post-proccess values end

  opVals = {
    cashInBnkArr: cash_in_bank,
    cashBelowZeroMonth: cash_in_bank.findIndex((x) => x < 0) ? `Month ${cashInBnkValIdx + 1}` : "False",

    timeForGrossProfitablity: `Month ${totalGrossProfitIdx + 1}`,
    finalCashPayBackPeriodVal: `Month ${cashInBnkValArrIdx + 1}`,
    customerAcquisitionCost: 0,
    lifetimeValuePerCustomer: 0,
    grossProfPerCustBefAds: grossProfitPerCustResult,
    // euityValueData: equityValRes,
    realisedRevenue: realized_revenue_per_order,
    cogsPerOrder: cogs_per_order,
    sdrSalaries: sdr_salaries,
    contacts: contacts,
    outboundLeads: daily_outbound_leads,
    outboundCustomers: daily_outbound_customers,
    outboundRevenue: daily_outbound_revenue,
    outboundGrossProfit: outbound_gross_profit,
    roi: [],
    advertisingSpend: inbound_ad_spend,
    reach: sumArray(reachValArrResult2),
    linkClicks: sumArray(linkClicksValArrResult2),
    pageViews: sumArray(pageViewsValTwodecimalResult),
    inboundLeads: inbound_leads,
    inboundCustomers: sumArray(inboundCustomersValArrResult2),
    inboundRevenue: sumArray(inbound_revenue),
    roas: inbound_roas > 0 ? inbound_roas : 0,
    inboundGrossProfit: sumArray(inbound_gross_profit),
    inboundROI: inbound_roi > 0 ? inbound_roi : 0,
    organicLeads: organic_leads,
    organicCustomers: organic_customers,
    organicRevenue: organic_revenue,
    organicGrossProfit: organic_profit,
    marketingSpend: total_marketing_spend,
    marketingCustomersAcquired: total_marketing_customers_acquired,
    marketingRevenue: total_marketing_revenue,
    marketingGrossProfit: sumArray(total_marketing_gross_profit),
    marketingROI: sumArray(marketing_roi),
    totalCustomerBase: total_customer_base,
    returningCustomers: returning_customers,
    monthlyReturningRevenue: sumArray(daily_returning_revenue),
    outputCostToMarketReturn: sumArray(daily_cost_to_market_returns),
    refundCostLoss: daily_costs_of_refunds,
    totalRefundOrders: daily_refunds,
    totalRefundLoss: daily_refunds_loss,
    totalReferrers: sumArray(daily_referrers), //C
    totalInvitees: sumArray(total_invitees),
    referralCustomers: daily_referral_customers,
    referralRevenue: sumArray(referral_revenue), //C
    totalGrossRevenue: sumArray(total_gross_revenue),
    totalGrossProfitBeforeAdspend: sumArray(total_gross_profit_before_adspend),
    grossMarginProfitBeforeAdspend: sumArray(gross_profit_margin_before_adspend),
    totalGrossProfitAfterAdspend: sumArray(gross_profit_after_adspend),
    grossMarginProfitAfterAdspend: gross_profit_margin_after_adspend,

    grossProfitBeforeAdspendPerCustPerMonth: gross_profit_per_customer_per_month,
    grossProfitAfterAdspendPerCustPerMonth: gross_profit_after_ads_per_customer_per_month,
    paymentProcessorFeesPerOrder: payment_processor_fees_per_order,
    merchantAccFeesPerOrder: merchant_account_fees_per_order,
    totalPaymentProcessorFees: sumArray(total_payment_processor_fees),
    totalmerchantAccFees: sumArray(total_merchant_account_fees),
    totalFixedCosts: total_fixed_costs,
    upFrontInvestmentCosts: upfront_investment_costs,

    earningBeforeIntAndTax: ebit,
    cashTaxes: cash_taxes,
    debtInterestPayable: daily_debt_payments,
    unleveredFreeCashFlow: daily_unlevered_free_cash_flow,
    enterPriseValue: daily_enterprise_value,
    plusCash: 0,

    lessDebt: daily_debt_balance,
    equityValue: equity_values,
    equityValueShare: equity_value_per_share,
    earningAfterIntAndTax: earnings_after_tax_interest,

    avgTerminalValue: average_terminal_value,
    cashROICompToDay: finalCashROICompToDayVal,
    overAllCashInBank: totalCashInBank,
    cashPaybackPeriod: cash_payback_period,
    equityValueTotal: equityValTotal,

    finalCashROICompToDay: finalCashROICompToDayVal,

    // Graphs
    totalGrossRevValRes: ebit,
    totalCustomerBaseVal: total_customer_base,
    equityValArr: equity_values,
    totalCustValRes: lifetime_value_per_customer,
    earBefIntTaxArr: [],
    unleveredFreeCashFlowVal: [],
    cashROIformattedArr: [],
    ltvArray: [],
    gpcArray: [],
  };

  console.log(opVals);

  return opVals;
}
