let tempbackendServerBaseURL = "http://localhost:7400";
let tempsocketURL = "http://localhost:7400";
let tempFrontEndURL = "http://localhost:3005";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  console.log("dev");
} else {
  tempbackendServerBaseURL = "https://api.usescalez.com";
  tempsocketURL = "https://api.usescalez.com";
  tempFrontEndURL = "https://app.usescalez.com";
}

export let backendServerBaseURL = tempbackendServerBaseURL;
export let socketURL = tempsocketURL;
export let frontURL = tempFrontEndURL;
