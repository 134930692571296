import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  createGoal,
  createMultipleGoalAi,
  createMultipleGoals,
  generateAiGoals,
  selectAiGeneratedGoals,
} from "../../../redux/slices/projectSlice";
import { Modal } from "react-bootstrap";

function AiGoalsPrompt({ hidePrompt, showPrompt, promptVisible }) {
  const [loaded, setLoader] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();

  const [selectedGoals, setSelected] = useState([]);
  const closeModalRef = useRef();

  const aigoals = useSelector(selectAiGeneratedGoals);

  const getGoalsUsingAi = async () => {
    await dispatch(generateAiGoals({ id: params.projectId, setLoader }));
  };

  const selectGoal = (index) => {
    let temp_result;
    if (selectedGoals.includes(index)) {
      // Create a copy of selectedGoals to avoid direct mutation
      temp_result = [...selectedGoals];
      // Find the position of the element to remove
      const elementIndex = temp_result.indexOf(index);
      // Remove the element at the found position
      temp_result.splice(elementIndex, 1);
    } else {
      // Add the new index to the array
      temp_result = [...selectedGoals, index];
    }
    // Update the state with the new array
    setSelected(temp_result);
  };

  const createAiGenerateGoals = async () => {
    try {
      setisSubmitting(true);
      if (aigoals && selectedGoals) {
        let processed_goals = await selectedGoals.map((ind) => {
          return aigoals[ind];
        });

        processed_goals = await processed_goals.map((goal) => {
          let keymetric = goal.key_metrics.map((name) => {
            return {
              name,
              startValue: null,
              targetValue: null,
            };
          });

          return {
            name: goal.goal,
            description: goal.description,
            startDate: new Date(),
            endDate: null,
            members: [],
            projectId: params.projectId,
            keymetric,
            confidence: "",
          };
        });

        await dispatch(createMultipleGoalAi({ goals: processed_goals, projectId: params.projectId, closeModal: hidePrompt }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setisSubmitting(false);
    }
  };

  useEffect(() => {
    if (promptVisible) {
      getGoalsUsingAi();
    }
  }, [promptVisible]);

  useEffect(() => {
    console.log(aigoals);
  }, [aigoals]);

  return (
    <Modal size="lg" centered show={promptVisible} onHide={() => hidePrompt(false)} aria-labelledby="ai-goal-prompt">
      <Modal.Header className="border-0" closeButton>
        <img src="/static/icons/ai-icon.svg" alt="ai-icon" />
        <h3>AI Goals</h3>
      </Modal.Header>
      <Modal.Body>
        {loaded ? (
          aigoals && (
            <>
              <table className=" w-100">
                <tr>
                  <th style={{ width: "10%" }}></th>
                  <th style={{ width: "60%" }}>Name</th>
                  <th style={{ width: "15%" }}>Duration</th>
                  <th style={{ width: "10%" }}>Ideas</th>
                  <th style={{ width: "5%" }}></th>
                </tr>
              </table>

              <div className="accordion" id="goalAccordion">
                {aigoals.map((goal, index) => {
                  return (
                    <div className="accordion-item">
                      <h2 className="accordion-header border-0 outline-0" id={`heading-${index}`}>
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse-goal${index}`}
                          aria-expanded="true"
                          aria-controls={`collapse-goal${index}`}
                        >
                          <td style={{ width: "70%" }} className="text-truncate">
                            {goal.goal}
                          </td>
                          <td style={{ width: "15%" }}>-</td>
                          <td style={{ width: "10%" }}>0</td>
                          <td style={{ width: "5%" }}>
                            <div className="form-check">
                              <input
                                onClick={() => {
                                  selectGoal(index);
                                }}
                                className="form-check-input"
                                type="checkbox"
                                checked={selectedGoals.includes(index)}
                                id="flexCheckDefault"
                              />
                            </div>
                          </td>
                        </button>
                      </h2>
                      <div
                        id={`collapse-goal${index}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading-${index}`}
                        data-bs-parent="#goalAccordion"
                      >
                        <div className="accordion-body">
                          {goal.key_metrics.map((m) => {
                            return <div className="metric">{m}</div>;
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )
        ) : (
          <div className=" d-flex justify-content-center  w-100">
            <div className="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="border-0">
        <button type="button" class="btn  btn-outline-danger" data-bs-dismiss="modal" onClick={() => {}}>
          Cancel
        </button>

        <button
          onClick={() => {
            createAiGenerateGoals();
          }}
          type="submit"
          className={"btn " + (selectedGoals.length > 0 ? "btn-primary" : "btn-secondary")}
        >
          Import
          {isSubmitting && (
            <div className="spinner-border spinner-border-sm text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AiGoalsPrompt;
