import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { createMultipleIdeaAi, generateAiIdeas, selectAiGeneratedIdeas } from "../../../redux/slices/projectSlice";
import { selectallGrowthLevers } from "../../../redux/slices/settingSlice";
import { Modal } from "react-bootstrap";

function AiIdeasPrompt({ hidePrompt, showPrompt, promptVisible }) {
  const [loaded, setLoader] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();

  const [selectedIdeas, setSelected] = useState([]);

  const aiIdeas = useSelector(selectAiGeneratedIdeas);

  const getIdeasUsingAi = async () => {
    await dispatch(generateAiIdeas({ id: params.projectId, setLoader }));
  };

  const allGrowthLevers = useSelector(selectallGrowthLevers);
  let leversData = allGrowthLevers.map((x) => {
    return {
      name: x.name,
      color: x.color,
    };
  });

  const selectIdea = (index) => {
    let temp_result;
    if (selectedIdeas.includes(index)) {
      // Create a copy of selectedIdeas to avoid direct mutation
      temp_result = [...selectedIdeas];
      // Find the position of the element to remove
      const elementIndex = temp_result.indexOf(index);
      // Remove the element at the found position
      temp_result.splice(elementIndex, 1);
    } else {
      // Add the new index to the array
      temp_result = [...selectedIdeas, index];
    }
    // Update the state with the new array
    setSelected(temp_result);
  };

  const createAiGenerateIdeas = async () => {
    try {
      setisSubmitting(true);
      if (aiIdeas && selectedIdeas) {
        // get values at the index
        let processed_ideas = await selectedIdeas.map((ind) => {
          return aiIdeas[ind];
        });

        // goal and keymetric id processed at backend

        processed_ideas = await processed_ideas.map((idea) => {
          return {
            name: idea.name,
            description: idea.description,
            goal: idea.goal,
            keymetric: idea.keymetric,
            lever: idea.lever,
            impact: idea.impact,
            confidence: idea.confidence,
            ease: idea.ease,
            score: idea.score,
            project: params.projectId,
          };
        });

        await dispatch(createMultipleIdeaAi({ ideas: processed_ideas, projectId: params.projectId, closeModal: hidePrompt }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setisSubmitting(false);
    }
  };

  useEffect(() => {
    if (promptVisible) {
      getIdeasUsingAi();
    }
  }, [promptVisible]);

  return (
    <Modal size="lg" show={promptVisible} centered onHide={() => hidePrompt()} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header className="border-0" closeButton>
        <img src="/static/icons/ai-icon.svg" alt="ai-icon" className="me-1" />
        <h3>AI Ideas</h3>
      </Modal.Header>
      <Modal.Body>
        {loaded ? (
          aiIdeas && (
            <>
              <table className="text-start w-100 mb-3">
                <tr>
                  <th style={{ width: "30%" }}>Name</th>
                  <th style={{ width: "25%" }}>Key Metric</th>
                  <th style={{ width: "25%" }}>Lever</th>
                  <th style={{ width: "10%" }}>I.C.E</th>
                  <th style={{ width: "5%" }}></th>
                </tr>
              </table>
              <div className="idea-row-parent overflow-auto" style={{ maxHeight: "500px" }}>
                {aiIdeas.map((idea, index) => {
                  return (
                    <div className="idea-row w-100 ">
                      <table className="text-start w-100">
                        <tr>
                          <td style={{ width: "30%" }} className="text-truncate">
                            {idea?.name.length > 25 ? idea?.name.substring(0, 25) + "..." : idea?.name}
                          </td>
                          <td className="text-truncate" style={{ width: "25%" }}>
                            {idea?.key_metric}
                          </td>
                          <td className="text-truncate" style={{ width: "25%" }}>
                            {leversData.map((lever) => {
                              if (idea.lever === lever.name) {
                                let chipColor;
                                switch (lever.color) {
                                  case "Blue":
                                    chipColor = "blue-chip";
                                    break;
                                  case "Orange":
                                    chipColor = "orange-chip";
                                    break;
                                  case "Green":
                                    chipColor = "green-chip";
                                    break;
                                  case "Red":
                                    chipColor = "red-chip";
                                    break;
                                  case "Yellow":
                                    chipColor = "yellow-chip";
                                    break;
                                  default:
                                    chipColor = "blue-chip";
                                    break;
                                }
                                return (
                                  <span className={chipColor} key={lever.name}>
                                    {idea.lever}
                                  </span>
                                );
                              }
                              return null;
                            })}
                          </td>
                          <td style={{ width: "10%" }}>
                            {idea.score >= 7 ? (
                              <span className="border  rounded p-1 border-success text-success">{idea.score.toFixed(2)}</span>
                            ) : idea.score >= 4 ? (
                              <span className="border  rounded p-1 border-warning text-warning">{idea.score.toFixed(2)}</span>
                            ) : (
                              <span className="border  rounded p-1 border-danger text-danger">{idea.score.toFixed(2)}</span>
                            )}
                          </td>
                          <td style={{ width: "5%" }}>
                            <div className="form-check">
                              <input
                                onClick={() => {
                                  selectIdea(index);
                                }}
                                className="form-check-input"
                                type="checkbox"
                                checked={selectedIdeas.includes(index)}
                                id="flexCheckDefault"
                              />
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  );
                })}
              </div>
            </>
          )
        ) : (
          <div className=" d-flex justify-content-center  w-100">
            <div className="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="border-0">
        <button type="button" class="btn  btn-outline-danger" data-bs-dismiss="modal" onClick={() => {}}>
          Cancel
        </button>

        <button
          onClick={() => {
            createAiGenerateIdeas();
          }}
          disabled={selectedIdeas.length == 0}
          type="submit"
          className={"btn " + (selectedIdeas.length > 0 ? "btn-primary" : "btn-secondary")}
        >
          Import
          {isSubmitting && (
            <div className="spinner-border spinner-border-sm text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AiIdeasPrompt;
