import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Spinner from "../../components/common/Spinner";
import { editModel, getSingleModel, selectsingleModelInfo, updateselectedModel } from "../../redux/slices/modelSlice";
import DCF from "../../utils/DCF";
import { formatNumber } from "../../utils/formatNumber";
import { isRoleAdmin, isRoleMember, isTypeOwner } from "../../utils/permissions";
import CreateNewModelDialog from "./CreateNewModelDialog";
import moment from "moment";

function ModelInfo() {
  const [selectedMenu, setselectedMenu] = useState("Input");
  const [DCFResponse, setDCFResponse] = useState(null);
  const [currency, setcurrency] = useState("rupee");
  const params = useParams();
  const modelId = params.modelId;
  const dispatch = useDispatch();
  const singleModelInfo = useSelector(selectsingleModelInfo);
  const [startingDate, setstartingDate] = useState(new Date());
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(true);
  const [isBlueChecked, setIsBlueChecked] = useState(true);
  const [isInboundBlueChecked, setIsInboundBlueChecked] = useState(true);
  const [isOrganicBlueChecked, setIsOrganicBlueChecked] = useState(true);
  const currencyValue = localStorage.getItem("currency", "");
  console.log("currencyValue :>> ", currencyValue);
  const SampleData = [
    // {
    //   // Set your first set of values here
    //   targetDate: 0,
    //   startDate: null,
    //   cashInBank: 1000000,
    //   numberOfCustomers: 0,
    //   max_customers: 100000.0,
    //   max_revenue: 1000000.0,
    //   average_revenue_per_customer: 800.0,
    //   time_to_market: 1,
    //   time_to_sell: 1,
    //   time_to_collect: 2,
    //   max_leads: 150,

    //   // Financial Data
    //   avgOrderValue: 800,
    //   realisationRate: 90,
    //   unitsOrder: 1,
    //   blendedCogs: 30,

    //   // Marketing Metrics
    //   outboundSalary: 0,
    //   numberOfContactsPerSdr: 7500,
    //   numberOfSDR: 0,
    //   contactToLeadConversionRate: 2,
    //   leadToCustomerConversionRate: 8,
    //   organicleadToCustomerConversionRate: 20,

    //   monthlyAdSpend: 1000000,
    //   cpm: 150,
    //   clickThroughRate: 1,
    //   landingPageView: 70,
    //   leadGenerationRate: 100,
    //   conversionRate: 2,

    //   organicViewsPerMonth: 0,
    //   organicViewsLeadToConversionRate: 0,
    //   organicleadToCustomerConversionRate: 20,

    //   // Retention Metrics
    //   returningCustomerRate: 20,
    //   timeToReturn: 1,
    //   costToMarketReturn: 7.5,

    //   // Virality Metrics
    //   referresOutOfCustomers: 0,
    //   inviteesPerReferral: 5,
    //   inviteesConversionRate: 5.0,

    //   // Admin
    //   refundRate: 1.5,
    //   fixedLossPerRefund: 15,
    //   paymentProcessorFees: 2,
    //   merchantAccountFees: 0.5,
    //   fixedCostPerMonth: 10000,
    //   fixedCostIncreasePerHundredCustomers: 1000,
    //   upFrontInvestmentCost: 0,
    //   debt: 0,
    //   debtInterestRate: 6,

    //   // Valuations
    //   taxRate: 20,
    //   numberOfShares: 20000,
    //   projectionPeriod: 60,
    //   discountRate: 12,
    //   perpetualGrowthRate: 3,
    //   // ...other values...
    // },
    // {
    //   // Set your second set of values here
    //   targetDate: 0,
    //   startDate: null,
    //   cashInBank: 2000000,
    //   numberOfCustomers: 0,
    //   max_customers: 100000.0,
    //   max_revenue: 1000000.0,
    //   average_revenue_per_customer: 800.0,
    //   time_to_market: 1,
    //   time_to_sell: 1,
    //   time_to_collect: 2,
    //   max_leads: 150,

    //   // Financial Data
    //   avgOrderValue: 900,
    //   realisationRate: 90,
    //   unitsOrder: 1,
    //   blendedCogs: 30,

    //   // Marketing Metrics
    //   outboundSalary: 0,
    //   numberOfContactsPerSdr: 9500,
    //   numberOfSDR: 0,
    //   contactToLeadConversionRate: 2,
    //   leadToCustomerConversionRate: 8,
    //   organicleadToCustomerConversionRate: 20,

    //   monthlyAdSpend: 1000000,
    //   cpm: 150,
    //   clickThroughRate: 1,
    //   landingPageView: 70,
    //   leadGenerationRate: 100,
    //   conversionRate: 2,

    //   organicViewsPerMonth: 0,
    //   organicViewsLeadToConversionRate: 0,
    //   organicleadToCustomerConversionRate: 20,

    //   // Retention Metrics
    //   returningCustomerRate: 20,
    //   timeToReturn: 1,
    //   costToMarketReturn: 7.5,

    //   // Virality Metrics
    //   referresOutOfCustomers: 0,
    //   inviteesPerReferral: 5,
    //   inviteesConversionRate: 5.0,

    //   // Admin
    //   refundRate: 1.5,
    //   fixedLossPerRefund: 15,
    //   paymentProcessorFees: 2,
    //   merchantAccountFees: 0.5,
    //   fixedCostPerMonth: 10000,
    //   fixedCostIncreasePerHundredCustomers: 1000,
    //   upFrontInvestmentCost: 0,
    //   debt: 0,
    //   debtInterestRate: 6,

    //   // Valuations
    //   taxRate: 20,
    //   numberOfShares: 20000,
    //   projectionPeriod: 60,
    //   discountRate: 12,
    //   perpetualGrowthRate: 3,
    //   // ...other values...
    // },
    // {
    //   // Set your third set of values here
    //   targetDate: 0,
    //   startDate: null,
    //   cashInBank: 3000000,
    //   numberOfCustomers: 0,
    //   max_customers: 100000.0,
    //   max_revenue: 1000000.0,
    //   average_revenue_per_customer: 800.0,
    //   time_to_market: 1,
    //   time_to_sell: 1,
    //   time_to_collect: 2,
    //   max_leads: 150,

    //   // Financial Data
    //   avgOrderValue: 800,
    //   realisationRate: 90,
    //   unitsOrder: 1,
    //   blendedCogs: 30,

    //   // Marketing Metrics
    //   outboundSalary: 0,
    //   numberOfContactsPerSdr: 7500,
    //   numberOfSDR: 0,
    //   contactToLeadConversionRate: 2,
    //   leadToCustomerConversionRate: 8,
    //   organicleadToCustomerConversionRate: 20,

    //   monthlyAdSpend: 1000000,
    //   cpm: 150,
    //   clickThroughRate: 1,
    //   landingPageView: 70,
    //   leadGenerationRate: 100,
    //   conversionRate: 2,

    //   organicViewsPerMonth: 0,
    //   organicViewsLeadToConversionRate: 0,
    //   organicleadToCustomerConversionRate: 20,

    //   // Retention Metrics
    //   returningCustomerRate: 20,
    //   timeToReturn: 1,
    //   costToMarketReturn: 7.5,

    //   // Virality Metrics
    //   referresOutOfCustomers: 0,
    //   inviteesPerReferral: 5,
    //   inviteesConversionRate: 5.0,

    //   // Admin
    //   refundRate: 1.5,
    //   fixedLossPerRefund: 15,
    //   paymentProcessorFees: 2,
    //   merchantAccountFees: 0.5,
    //   fixedCostPerMonth: 10000,
    //   fixedCostIncreasePerHundredCustomers: 1000,
    //   upFrontInvestmentCost: 0,
    //   debt: 0,
    //   debtInterestRate: 6,

    //   // Valuations
    //   taxRate: 20,
    //   numberOfShares: 20000,
    //   projectionPeriod: 60,
    //   discountRate: 12,
    //   perpetualGrowthRate: 3,
    //   // ...other values...
    // },
    // {
    //   targetDate: 0,
    //   cashInBank: 1000000.0,
    //   numberOfCustomers: 0.0,
    //   avgOrderValue: 800.0,
    //   realisationRate: 0.9,
    //   unitsOrder: 1,
    //   blendedCogs: 0.3,
    //   outboundSalary: 25000.0,
    //   numberOfContactsPerSdr: 250.0,
    //   numberOfSDR: 10.0,
    //   contactToLeadConversionRate: 0.02,
    //   leadToCustomerConversionRate: 0.08,
    //   monthlyAdSpend: 1500000.0,
    //   cpm: 150.0,
    //   clickThroughRate: 0.01,
    //   landingPageView: 0.7,
    //   leadGenerationRate: 1.0,
    //   conversionRate: 0.02,
    //   organicViewsPerMonth: 750000.0,
    //   organicViewsLeadToConversionRate: 0.1,
    //   organicleadToCustomerConversionRate: 0.1,
    //   returningCustomerRate: 0.2,
    //   timeToReturn: 1,
    //   costToMarketReturn: 0.075,
    //   referresOutOfCustomers: 0.05,
    //   inviteesPerReferral: 5,
    //   inviteesConversionRate: 0.05,
    //   refundRate: 0.015,
    //   fixedLossPerRefund: 15.0,
    //   paymentProcessorFees: 0.02,
    //   merchantAccountFees: 0.005,
    //   fixedCostPerMonth: 300000.0,
    //   fixedCostIncreasePerHundredCustomers: 1000.0,
    //   upFrontInvestmentCost: 0.0,
    //   debt: 1000000.0,
    //   debtInterestRate: 0.06,
    //   taxRate: 0.2,
    //   numberOfShares: 20000,
    //   projectionPeriod: 120,
    //   discountRate: 0.12,
    //   perpetualGrowthRate: 0.03,
    //   startDate: null,
    //   max_customers: 100000.0,
    //   max_revenue: 1000000.0,
    //   average_revenue_per_customer: 800.0,
    //   time_to_market: 1,
    //   time_to_sell: 1,
    //   time_to_collect: 2,
    //   max_leads: 150,
    //   organicleadToCustomerConversionRate: 20,
    // },
    {
      // Set your third set of values here
      targetDate: 0,
      startDate: null,
      cashInBank: 1000000.0,
      numberOfCustomers: 0.0,
      max_customers: 100000.0,
      max_revenue: 1000000.0,
      average_revenue_per_customer: 800.0,
      time_to_market: 1,
      time_to_sell: 1,
      time_to_collect: 2,
      max_leads: 150,

      // Financial Data
      avgOrderValue: 800.0,
      realisationRate: 0.9,
      unitsOrder: 1,
      blendedCogs: 0.3,

      // Marketing Metrics
      outboundSalary: 25000.0,
      numberOfContactsPerSdr: 250.0,
      numberOfSDR: 10.0,
      contactToLeadConversionRate: 0.02,
      leadToCustomerConversionRate: 0.08,
      organicleadToCustomerConversionRate: 0.1,

      monthlyAdSpend: 1500000.0,
      cpm: 150.0,
      clickThroughRate: 0.01,
      landingPageView: 0.7,
      leadGenerationRate: 1.0,
      conversionRate: 0.02,

      organicViewsPerMonth: 25000.0,
      organicViewsLeadToConversionRate: 0.1,
      organicleadToCustomerConversionRate: 0.1,

      // Retention Metrics
      returningCustomerRate: 0.2,
      timeToReturn: 1,
      costToMarketReturn: 0.075,

      // Virality Metrics
      referresOutOfCustomers: 0.05,
      inviteesPerReferral: 5,
      inviteesConversionRate: 0.05,

      // Admin
      refundRate: 0.015,
      fixedLossPerRefund: 15.0,
      paymentProcessorFees: 0.02,
      merchantAccountFees: 0.005,
      fixedCostPerMonth: 10000.0,
      fixedCostIncreasePerHundredCustomers: 1000.0,
      upFrontInvestmentCost: 0.0,
      debt: 1000000.0,
      debtInterestRate: 0.06,

      // Valuations
      taxRate: 0.2,
      numberOfShares: 20000,
      projectionPeriod: 120,
      discountRate: 0.12,
      perpetualGrowthRate: 0.03,
      // ...other values...
    },
  ];
  const [sampleIndex, setSampleIndex] = useState(4);

  // const ProjectsMenus = [
  //   {
  //     name: "Input",
  //   },
  //   {
  //     name: "Output",
  //   },
  // ];
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const handleImageClick = () => {
    setIsBlueChecked((prev) => !prev);
    formik.setFieldValue("outboundSalary", "");
    formik.setFieldValue("numberOfContactsPerSdr", "");
    formik.setFieldValue("numberOfSDR", "");
    formik.setFieldValue("contactToLeadConversionRate", "");
    formik.setFieldValue("leadToCustomerConversionRate", "");
    formik.setFieldValue("organicleadToCustomerConversionRate", "");
  };

  const handleInboundImageClick = () => {
    setIsInboundBlueChecked((prev) => !prev);
    formik.setFieldValue("monthlyAdSpend", "");
    formik.setFieldValue("cpm", "");
    formik.setFieldValue("clickThroughRate", "");
    formik.setFieldValue("landingPageView", "");
    formik.setFieldValue("conversionRate", "");
    formik.setFieldValue("leadGenerationRate", "");
  };

  const handleOrganicImageClick = () => {
    setIsOrganicBlueChecked((prev) => !prev);
    formik.setFieldValue("organicViewsPerMonth", "");
    formik.setFieldValue("organicViewsLeadToConversionRate", "");
    formik.setFieldValue("organicleadToCustomerConversionRate", "");
  };
  const NewProjectSchema = Yup.object().shape({
    // Starting State
    targetDate: Yup.number().required("Target Date is required"),
    cashInBank: Yup.number().required("Cash in bank is required"),
    numberOfCustomers: Yup.number().required("Number of customers is required"),

    max_customers: Yup.number().required("Maximum number of customers is required"),
    max_revenue: Yup.number().required("Maximum revenue is required"),
    average_revenue_per_customer: Yup.number().required("Average Revenue per customer is required"),
    time_to_market: Yup.number().required("Time to market is required"),
    time_to_sell: Yup.number().required("Time to sell is required"),
    time_to_collect: Yup.number().required("Time to collect is required"),
    max_leads: Yup.number().required("Maximum Leads is required"),

    // Financial Data
    avgOrderValue: Yup.number().required("Average order value is required"),
    realisationRate: Yup.number().required("Realisation Rate is required"),
    unitsOrder: Yup.number().required("Unit order is required"),
    blendedCogs: Yup.number().required("Blended COGS is required"),

    // Marketing Metrics
    // outboundSalary: Yup.number().required("Outbound salary is required"),
    // numberOfContactsPerSdr: Yup.number().required("Number Of Contacts PerMonth Per SDR is required"),
    // numberOfSDR: Yup.number().required("Number Of SDRs is required"),
    // contactToLeadConversionRate: Yup.number().required("Contact To Lead Conversion Rate is required"),
    // leadToCustomerConversionRate: Yup.number().required("Lead To Customer Conversion Rate Outbound is required"),
    // organicleadToCustomerConversionRate: Yup.number().required("Lead To Customer Conversion Rate Organic is required"),

    // monthlyAdSpend: Yup.number().required("Monthly Ad Spend is required"),
    // cpm: Yup.number().required("cpm is required"),
    // clickThroughRate: Yup.number().required("CTR is required"),
    // landingPageView: Yup.number().required("Landing Page View Rate is required"),
    // leadGenerationRate: Yup.number().required("Lead Generation Rate is required"),
    // conversionRate: Yup.number().required("Conversion Rate is required"),

    // organicViewsPerMonth: Yup.number().required("Organic Views Per Month is required"),
    // organicViewsLeadToConversionRate: Yup.number().required("Organic View To Lead Conversion Rate is required"),
    // organicleadToCustomerConversionRate: Yup.number().required("Lead To Customer Conversion Rate Organic is required"),

    outboundSalary: Yup.number().optional(),
    numberOfContactsPerSdr: Yup.number().optional(),
    numberOfSDR: Yup.number().optional(),
    contactToLeadConversionRate: Yup.number().optional(),
    leadToCustomerConversionRate: Yup.number().optional(),
    organicleadToCustomerConversionRate: Yup.number().optional(),

    monthlyAdSpend: Yup.number().optional(),
    cpm: Yup.number().optional(),
    clickThroughRate: Yup.number().optional(),
    landingPageView: Yup.number().optional(),
    leadGenerationRate: Yup.number().optional(),
    conversionRate: Yup.number().optional(),

    organicViewsPerMonth: Yup.number().optional(),
    organicViewsLeadToConversionRate: Yup.number().optional(),
    organicleadToCustomerConversionRate: Yup.number().optional(),

    // Retention Metrics
    returningCustomerRate: Yup.number().required("Returning CustomerRate is required"),
    timeToReturn: Yup.number().required("Time To Return Months is required"),
    costToMarketReturn: Yup.number().required("Cost To Market Return Percent is required"),

    // Virality Metrics
    referresOutOfCustomers: Yup.number().required("Referrers Out Of Customers Percent is required"),
    inviteesPerReferral: Yup.number().required("Invitees Per Referral is required"),
    inviteesConversionRate: Yup.number().required("Invitees Conversion Rate is required"),

    // Admin
    refundRate: Yup.number().required("Refund Rate Percent is required"),
    fixedLossPerRefund: Yup.number().required("Fixed Loss Per Refund Exc ProductCost is required"),
    paymentProcessorFees: Yup.number().required("Payment Processor Fees is required"),
    merchantAccountFees: Yup.number().required("Merchant Account Fees is required"),
    fixedCostPerMonth: Yup.number().required("Fixed Costs Per Month is required"),
    fixedCostIncreasePerHundredCustomers: Yup.number().required("Fixed Costs Increase Per100 Customers Per Month is required"),
    upFrontInvestmentCost: Yup.number().required("Upfront Investment Costs is required"),
    debt: Yup.number().required("Debt is required"),
    debtInterestRate: Yup.number().required("Debt Interest Rate Percent Annual is required"),

    // Valuations
    taxRate: Yup.number().required("Tax Rate Percent is required"),
    numberOfShares: Yup.number().required("Number Of Shares is required"),
    projectionPeriod: Yup.number().required("Projection Period Months is required"),
    discountRate: Yup.number().required("Discount Rate Percent is required"),
    perpetualGrowthRate: Yup.number().required("Perpetual Growth Rate Percent is required"),
  });

  const formik = useFormik({
    initialValues: {
      // Starting State
      targetDate: "",
      cashInBank: "",
      numberOfCustomers: "",

      max_customers: "",
      max_revenue: "",
      average_revenue_per_customer: "",
      time_to_market: "",
      time_to_sell: "",
      time_to_collect: "",
      max_leads: "",

      // Financial Data
      avgOrderValue: "",
      realisationRate: "",
      unitsOrder: "",
      blendedCogs: "",

      // Marketing Metrics
      outboundSalary: "",
      numberOfContactsPerSdr: "",
      numberOfSDR: "",
      contactToLeadConversionRate: "",
      leadToCustomerConversionRate: "",
      organicleadToCustomerConversionRate: "",

      monthlyAdSpend: "",
      cpm: "",
      clickThroughRate: "",
      landingPageView: "",
      leadGenerationRate: "",
      conversionRate: "",

      organicViewsPerMonth: "",
      organicViewsLeadToConversionRate: "",
      organicleadToCustomerConversionRate: "",

      // Retention Metrics
      returningCustomerRate: "",
      timeToReturn: "",
      costToMarketReturn: "",

      // Virality Metrics
      referresOutOfCustomers: "",
      inviteesPerReferral: "",
      inviteesConversionRate: "",

      // Admin
      refundRate: "",
      fixedLossPerRefund: "",
      paymentProcessorFees: "",
      merchantAccountFees: "",
      fixedCostPerMonth: "",
      fixedCostIncreasePerHundredCustomers: "",
      upFrontInvestmentCost: "",
      debt: "",
      debtInterestRate: "",

      // Valuations
      taxRate: "",
      numberOfShares: "",
      projectionPeriod: "",
      discountRate: "",
      perpetualGrowthRate: "",
    },
    validateOnBlur: true,
    validationSchema: NewProjectSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);
      setSubmitting(true);

      // alert(values.organicleadToCustomerConversionRate);
      // alert(values.leadToCustomerConversionRate);

      const GeneratedDCFResponse = DCF({
        // Starting State
        startDate: startingDate,
        initial_cash: values?.cashInBank,
        initial_customers: values?.numberOfCustomers,

        // Financial Data
        average_order_value: values?.avgOrderValue,
        realisation_rate: values?.realisationRate,
        units_per_order: values?.unitsOrder,
        cogs_percentage: values?.blendedCogs,

        // Marketing Metrics
        outbound_salary: values?.outboundSalary,
        contacts_per_day_per_sdr: values?.numberOfContactsPerSdr,
        number_of_sdrs: values?.numberOfSDR,
        contact_to_lead_conversion_rate: values?.contactToLeadConversionRate,
        lead_to_customer_conversion_rate_outbound: values?.leadToCustomerConversionRate,
        organic_lead_to_customer_conversion_rate: values?.organicleadToCustomerConversionRate,

        inbound_daily_ad_spend: values?.monthlyAdSpend,

        inbound_cpm: values?.cpm,
        inbound_ctr: values?.clickThroughRate,
        inbound_landing_page_view_rate: values?.landingPageView,
        inbound_lead_generation_rate: values?.leadGenerationRate,
        inbound_conversion_rate: values?.conversionRate,

        organic_views_per_day: values?.organicViewsPerMonth,
        organic_view_to_lead_conversion_rate: values?.organicViewsLeadToConversionRate,

        // Retention Metrics
        returning_customer_rate: values?.returningCustomerRate,
        time_to_return: values?.timeToReturn,
        cost_to_market_return_percentage: values?.costToMarketReturn,

        // Virality Metrics
        referrers_out_of_customers_percentage: values?.referresOutOfCustomers,
        invitees_per_referral: values?.inviteesPerReferral,
        invitees_conversion_rate: values?.inviteesConversionRate,

        // Admin
        refund_rate_percentage: values?.refundRate,
        fixed_loss_per_refund: values?.fixedLossPerRefund,
        payment_processor_fees_percentage: values?.paymentProcessorFees,
        merchant_account_fees_percentage: values?.merchantAccountFees,
        fixed_costs_per_day: values?.fixedCostPerMonth,
        fixed_costs_increase_per_100_customers_per_day: values?.fixedCostIncreasePerHundredCustomers,
        upfront_investment_costs: values?.upFrontInvestmentCost,
        debt: values?.debt,
        debt_interest_rate_annual: values?.debtInterestRate,

        // Valuations
        tax_rate_percentage: values?.taxRate,
        number_of_shares: values?.numberOfShares,
        projection_period_days: values?.projectionPeriod,
        discount_rate_percentage: values?.discountRate,
        perpetual_growth_rate_percentage: values?.perpetualGrowthRate,

        //new
        max_customers: values?.max_customers,
        max_revenue: values?.max_revenue,
        average_revenue_per_customer: values?.average_revenue_per_customer,
        time_to_market: values?.time_to_market,
        time_to_sell: values?.time_to_sell,
        time_to_collect: values?.time_to_collect,
        max_leads: values?.max_leads,
      });

      console.log(GeneratedDCFResponse);
      setDCFResponse(GeneratedDCFResponse);
      await dispatch(
        editModel({
          modelId,
          name: singleModelInfo.name,
          values: values,
          startingDate,
        })
      );
      await delay(3000);
      setselectedMenu("Output");
      console.log(GeneratedDCFResponse);
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  let options = {
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#97a4af",
          usePointStyle: true,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      yAxes: {
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        ticks: {
          beginAtZero: true,
          stepSize: 10,
          fontSize: 12,
          color: "#97a4af",
          fontFamily: "Open Sans, sans-serif",
          padding: 10,
          postfix: "k",
        },
      },
      xAxes: {
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        ticks: {
          fontSize: 12,
          color: "#97a4af",
          fontFamily: "Open Sans, sans-serif",
          padding: 5,
        },
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "index",
      intersect: false,
    },
  };

  const setRandomValues = () => {
    setstartingDate(new Date().toISOString().slice(0, 10));

    const sampleValues = SampleData[sampleIndex];

    // Update the form values
    formik.setValues(sampleValues);

    // Cycle to the next set of sample values
    setSampleIndex((sampleIndex + 1) % SampleData.length);
  };

  function truncate(num, places) {
    return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
  } //C

  useEffect(() => {
    dispatch(
      getSingleModel({
        modelId,
      })
    );
    setTimeout(() => {
      setShowLoader(false);
    }, 2000);
  }, []);

  useEffect(async () => {
    await dispatch(
      getSingleModel({
        modelId,
      })
    );
    setTimeout(() => {
      setShowLoader(false);
    }, 2000);
  }, []);

  useEffect(async () => {
    if (singleModelInfo && singleModelInfo?.data) {
      setstartingDate(singleModelInfo?.data?.startingDate);
      formik.setValues(singleModelInfo?.data);
    }
  }, [singleModelInfo]);

  return (
    <div>
      <div
        className="d-flex"
        style={{
          position: "fixed",
          top: "59px",
          display: "flex",
          alignItems: "flex-start",
          zIndex: "998",
          background: "#fff",
          width: "100%",
          padding: "17px 59px 18px 29px",
          borderBottom: "1px solid rgb(222, 226, 230)",
          borderTop: "1px solid rgb(222, 226, 230)",
        }}
      >
        {showLoader && (
          <div
            style={{
              width: "100%",
              height: "100%",
              zIndex: "999",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              paddingRight: "100px",
              paddingBottom: "200px",
              background: "#FBFBFB",
            }}
          >
            <Spinner />
          </div>
        )}
        <div>
          {/* <p className="text-secondary" style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => navigate('/models')}><span></span> All Models</p> */}
          <span className="mb-1 d-flex align-items-center">
            <h1>{singleModelInfo?.name} </h1>
            {isTypeOwner() || isRoleAdmin() || isRoleMember() ? (
              <span
                className="cp"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  dispatch(updateselectedModel(singleModelInfo));
                }}
                data-bs-toggle="modal"
                data-bs-target="#createNewModel"
              >
                <img src="/static/icons/u_edit-alt.svg" alt="" className="avatar2" />
              </span>
            ) : (
              <span></span>
            )}
          </span>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => {
                setRandomValues();
              }}
              disabled={isTypeOwner() || isRoleAdmin() || isRoleMember() ? false : true}
            >
              Generate Sample Values
            </button>

            <button
              loading={isSubmitting}
              type="button"
              className="btn btn-primary"
              style={{ minWidth: "10rem" }}
              onClick={handleSubmit}
              disabled={isTypeOwner() || isRoleAdmin() || isRoleMember() ? false : true}
            >
              Calculate Model
            </button>
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "6rem", marginBottom: "5rem" }}>
        {/* Tabs */}
        <div className="border-bottom mt-3 mb-3">
          <div className="flex-fill d-flex align-items-center">
            {/* {ProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })} */}
          </div>
        </div>

        {/* ---------- Input ---------- */}
        {/* {selectedMenu === "Input" && ( */}
        <div
          style={{
            display: "flex",
            overflowY: "auto",
            zIndex: "1",
            //  scrollBehavior: "smooth",
            // maxHeight: "620px",
            overflowX: "hidden",
          }}
        >
          <div style={DCFResponse ? { width: "50%" } : { width: "100%", height: "100%" }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div style={{ marginTop: "40px" }}>
                  {/* Starting State */}
                  <div className="card p-4 mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="body1">Starting State</p>

                      <img
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse1"
                        src="/static/icons/down-arrow.svg"
                        alt=""
                        className="cp"
                        height={"12px"}
                        width={"12px"}
                        style={{ marginRight: "1rem" }}
                      />
                    </div>

                    <div class="collapse show" id="collapse1">
                      <div className={DCFResponse ? { width: "50%" } : "row"}>
                        {/* Starting Date */}
                        {/* <div className="col-4 mt-3" style={DCFResponse ? {width: '100%'} : {width: '33.33%'}}>
                      <label htmlFor="" className="form-label">
                        Starting Date
                      </label>
                      <input
                        type="date"
                        value={startingDate}
                        onChange={(e) => {
                          if (e.target.value) {
                            setstartingDate(e.target.value);
                          }
                        }}
                        className="form-control"
                        placeholder="Starting date"
                      />
                    </div> */}

                        {/* Target Date */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Target Date
                          </label>
                          <select {...getFieldProps("targetDate")} className="form-control" placeholder="Select target date">
                            <option value="">Select Date</option>
                            {values &&
                              values.projectionPeriod &&
                              Array(values.projectionPeriod)
                                .fill(0)
                                .map((v, k) => {
                                  return <option value={k}>{moment().add(k, "days").format("DD MMM YYYY")}</option>;
                                })}
                          </select>
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.targetDate && errors.targetDate) ? "block" : "none",
                            }}
                          >
                            {errors.targetDate}
                          </span>
                        </div>
                        {/* Cash in Bank */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Cash in Bank
                          </label>
                          <input type="number" {...getFieldProps("cashInBank")} className="form-control" placeholder="Cash in Bank" />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.cashInBank && errors.cashInBank) ? "block" : "none",
                            }}
                          >
                            {errors.cashInBank}
                          </span>
                        </div>

                        {/* Initial Number of Customers */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Initial Number of Customers
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("numberOfCustomers")}
                            className="form-control"
                            placeholder="Number of Customers"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.numberOfCustomers && errors.numberOfCustomers) ? "block" : "none",
                            }}
                          >
                            {errors.numberOfCustomers}
                          </span>
                        </div>

                        {/* max Customers */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Maximum Customers
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("max_customers")}
                            className="form-control"
                            placeholder="Number of Customers"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.max_customers && errors.max_customers) ? "block" : "none",
                            }}
                          >
                            {errors.max_customers}
                          </span>
                        </div>

                        {/* max revnue */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Maximum Revenue
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("max_revenue")}
                            className="form-control"
                            placeholder="Maximum Revenue"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.max_revenue && errors.max_revenue) ? "block" : "none",
                            }}
                          >
                            {errors.max_revenue}
                          </span>
                        </div>

                        {/* avg revenue */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Average revenue per customer
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("average_revenue_per_customer")}
                            className="form-control"
                            placeholder="Average revenue per customer"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.average_revenue_per_customer && errors.average_revenue_per_customer)
                                ? "block"
                                : "none",
                            }}
                          >
                            {errors.average_revenue_per_customer}
                          </span>
                        </div>

                        {/* time to market */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Time to market
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("time_to_market")}
                            className="form-control"
                            placeholder="Time to market"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.time_to_market && errors.time_to_market) ? "block" : "none",
                            }}
                          >
                            {errors.time_to_market}
                          </span>
                        </div>

                        {/* time to sell */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Time to sell
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("time_to_sell")}
                            className="form-control"
                            placeholder="Time to sell"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.time_to_sell && errors.time_to_sell) ? "block" : "none",
                            }}
                          >
                            {errors.time_to_sell}
                          </span>
                        </div>

                        {/* time to collect */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Time to Collect
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("time_to_collect")}
                            className="form-control"
                            placeholder="Time to collect"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.time_to_collect && errors.time_to_collect) ? "block" : "none",
                            }}
                          >
                            {errors.time_to_collect}
                          </span>
                        </div>
                        {/* max leads */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Maximum Leads
                          </label>
                          <input type="number" {...getFieldProps("max_leads")} className="form-control" placeholder="Maximum leads" />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.max_leads && errors.max_leads) ? "block" : "none",
                            }}
                          >
                            {errors.max_leads}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Financial Data */}
                  <div className="card p-4 mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="body1">Financial Data</p>

                      <img
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse2"
                        src="/static/icons/down-arrow.svg"
                        alt=""
                        className="cp"
                        height={"12px"}
                        width={"12px"}
                        style={{ marginRight: "1rem" }}
                      />
                    </div>

                    <div class="collapse show" id="collapse2">
                      <div className="row">
                        {/* Average Order Value*/}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Average Order Value
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("avgOrderValue")}
                            className="form-control"
                            placeholder="Average Order Value"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.avgOrderValue && errors.avgOrderValue) ? "block" : "none",
                            }}
                          >
                            {errors.avgOrderValue}
                          </span>
                        </div>

                        {/* Realisation Rate */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Realisation Rate
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("realisationRate")}
                            className="form-control"
                            placeholder="Realisation Rate"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.realisationRate && errors.realisationRate) ? "block" : "none",
                            }}
                          >
                            {errors.realisationRate}
                          </span>
                        </div>

                        {/* Units/order */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Units/order
                          </label>
                          <input type="number" {...getFieldProps("unitsOrder")} className="form-control" placeholder="Units/order" />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.unitsOrder && errors.unitsOrder) ? "block" : "none",
                            }}
                          >
                            {errors.unitsOrder}
                          </span>
                        </div>

                        {/* Blended COGS % */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Blended COGS %
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("blendedCogs")}
                            className="form-control"
                            placeholder="Blended COGS %"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.blendedCogs && errors.blendedCogs) ? "block" : "none",
                            }}
                          >
                            {errors.blendedCogs}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Marketing Metrics */}
                  <div className="card p-4 mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="body1">Marketing Metrics</p>

                      <img
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse3"
                        src="/static/icons/down-arrow.svg"
                        alt=""
                        className="cp"
                        height={"12px"}
                        width={"12px"}
                        style={{ marginRight: "1rem" }}
                      />
                    </div>

                    <div class="collapse show" id="collapse3">
                      <div className="row">
                        {/* Heading */}
                        <div className="col-12 mt-3 d-flex align-items-center">
                          {
                            <img
                              alt=""
                              style={{
                                maxHeight: "1rem",
                                marginRight: "0.3rem",
                              }}
                              src={
                                values?.outboundSalary ||
                                values?.numberOfContactsPerSdr ||
                                values?.numberOfSDR ||
                                values?.contactToLeadConversionRate ||
                                values?.leadToCustomerConversionRate ||
                                values?.organicleadToCustomerConversionRate ||
                                isBlueChecked
                                  ? "/static/icons/blue_u_check-square.svg"
                                  : "/static/icons/grey-u-check-square.svg"
                              }
                              onClick={handleImageClick}
                            />
                          }
                          <label htmlFor="" className="form-label" style={{ marginTop: "0.20rem" }}>
                            Outbound
                          </label>
                        </div>

                        {/* Outbound Salary */}
                        {/* {formik.getFieldProps("outboundSalary", "") ? */}
                        <div className="col-4 mt-2" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Outbound Salary
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("outboundSalary")}
                            className="form-control"
                            placeholder="Outbound Salary"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.outboundSalary && errors.outboundSalary) ? "block" : "none",
                            }}
                          >
                            {errors.outboundSalary}
                          </span>
                        </div>
                        {/* : <div></div>} */}

                        {/* Number of Contacts per Month per SDR */}
                        <div className="col-4 mt-2" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Number of Contacts per Month per SDR
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("numberOfContactsPerSdr")}
                            className="form-control"
                            placeholder="Number of Contacts per Month per SDR"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.numberOfContactsPerSdr && errors.numberOfContactsPerSdr) ? "block" : "none",
                            }}
                          >
                            {errors.numberOfContactsPerSdr}
                          </span>
                        </div>

                        {/* Number of SDRs */}
                        <div className="col-4 mt-2" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Number of SDRs
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("numberOfSDR")}
                            className="form-control"
                            placeholder="Number of SDRs"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.numberOfSDR && errors.numberOfSDR) ? "block" : "none",
                            }}
                          >
                            {errors.numberOfSDR}
                          </span>
                        </div>

                        {/* Contact to Lead Conversion Rate% */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Contact to Lead Conversion Rate
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("contactToLeadConversionRate")}
                            className="form-control"
                            placeholder="Contact to Lead Conversion Rate"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.contactToLeadConversionRate && errors.contactToLeadConversionRate)
                                ? "block"
                                : "none",
                            }}
                          >
                            {errors.contactToLeadConversionRate}
                          </span>
                        </div>

                        {/* Lead to Customer Conversion Rate (Outbound) */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Lead to Customer Conversion Rate (Outbound)
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("leadToCustomerConversionRate")}
                            className="form-control"
                            placeholder="Lead to Customer Conversion Rate (Outbound)"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.leadToCustomerConversionRate && errors.leadToCustomerConversionRate)
                                ? "block"
                                : "none",
                            }}
                          >
                            {errors.leadToCustomerConversionRate}
                          </span>
                        </div>

                        {/* Heading */}
                        <div className="col-12 mt-3 d-flex align-items-center">
                          {
                            <img
                              src={
                                values?.monthlyAdSpend ||
                                values?.cpm ||
                                values?.clickThroughRate ||
                                values?.landingPageView ||
                                values?.conversionRate ||
                                values?.leadGenerationRate ||
                                isInboundBlueChecked
                                  ? "/static/icons/blue_u_check-square.svg"
                                  : "/static/icons/grey-u-check-square.svg"
                              }
                              alt=""
                              style={{
                                maxHeight: "1rem",
                                marginRight: "0.3rem",
                              }}
                              onClick={handleInboundImageClick}
                            />
                          }
                          <label htmlFor="" className="form-label" style={{ marginTop: "0.20rem" }}>
                            Inbound
                          </label>
                        </div>

                        {/* Monthly Ad Spend */}
                        <div className="col-4 mt-2" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Monthly Ad Spend
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("monthlyAdSpend")}
                            className="form-control"
                            placeholder="Outbound Salary"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.monthlyAdSpend && errors.monthlyAdSpend) ? "block" : "none",
                            }}
                          >
                            {errors.monthlyAdSpend}
                          </span>
                        </div>

                        {/* cpm */}
                        <div className="col-4 mt-2" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            CPM
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("cpm")}
                            className="form-control"
                            placeholder="Number of Contacts per Month per SDR"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.cpm && errors.cpm) ? "block" : "none",
                            }}
                          >
                            {errors.cpm}
                          </span>
                        </div>

                        {/* Click Through Rate */}
                        <div className="col-4 mt-2" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Click Through Rate
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("clickThroughRate")}
                            className="form-control"
                            placeholder="Click Through Rate"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.clickThroughRate && errors.clickThroughRate) ? "block" : "none",
                            }}
                          >
                            {errors.clickThroughRate}
                          </span>
                        </div>

                        {/* Landing Page View Rate */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Landing Page View Rate
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("landingPageView")}
                            className="form-control"
                            placeholder="Contact to Lead Conversion Rate"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.landingPageView && errors.landingPageView) ? "block" : "none",
                            }}
                          >
                            {errors.landingPageView}
                          </span>
                        </div>

                        {/* Lead Generation Rate */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Lead Generation Rate
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("leadGenerationRate")}
                            className="form-control"
                            placeholder="Lead to Customer Conversion Rate (Outbound"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.leadGenerationRate && errors.leadGenerationRate) ? "block" : "none",
                            }}
                          >
                            {errors.leadGenerationRate}
                          </span>
                        </div>

                        {/* Conversion Rate */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Conversion Rate
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("conversionRate")}
                            className="form-control"
                            placeholder="Conversion Rate"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.conversionRate && errors.conversionRate) ? "block" : "none",
                            }}
                          >
                            {errors.conversionRate}
                          </span>
                        </div>

                        {/* Organic */}
                        <div className="col-12 mt-3 d-flex align-items-center">
                          {
                            <img
                              src={
                                values?.organicViewsPerMonth ||
                                values?.organicViewsLeadToConversionRate ||
                                values?.organicleadToCustomerConversionRate ||
                                isOrganicBlueChecked
                                  ? "/static/icons/blue_u_check-square.svg"
                                  : "/static/icons/grey-u-check-square.svg"
                              }
                              onClick={handleOrganicImageClick}
                              alt=""
                              style={{
                                maxHeight: "1rem",
                                marginRight: "0.3rem",
                              }}
                            />
                          }
                          <label htmlFor="" className="form-label" style={{ marginTop: "0.20rem" }}>
                            Organic
                          </label>
                        </div>

                        {/* Organic Views per Month */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Organic Views per Month
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("organicViewsPerMonth")}
                            className="form-control"
                            placeholder="Organic Views per Month"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.organicViewsPerMonth && errors.organicViewsPerMonth) ? "block" : "none",
                            }}
                          >
                            {errors.organicViewsPerMonth}
                          </span>
                        </div>

                        {/* Organic View to Lead Conversion Rate */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Organic View to Lead Conversion Rate
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("organicViewsLeadToConversionRate")}
                            className="form-control"
                            placeholder="Organic View to Lead Conversion Rate"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.organicViewsLeadToConversionRate && errors.organicViewsLeadToConversionRate)
                                ? "block"
                                : "none",
                            }}
                          >
                            {errors.organicViewsLeadToConversionRate}
                          </span>
                        </div>

                        {/* Lead to Customer Conversion Rate (Organic) */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Lead to Customer Conversion Rate (Organic)
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("organicleadToCustomerConversionRate")}
                            className="form-control"
                            placeholder="Lead to Customer Conversion Rate (Organic)"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(
                                touched.organicleadToCustomerConversionRate && errors.organicleadToCustomerConversionRate
                              )
                                ? "block"
                                : "none",
                            }}
                          >
                            {errors.organicleadToCustomerConversionRate}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Retention Metrics */}
                  <div className="card p-4 mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="body1">Retention Metrics</p>

                      <img
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse4"
                        src="/static/icons/down-arrow.svg"
                        alt=""
                        className="cp"
                        height={"12px"}
                        width={"12px"}
                        style={{ marginRight: "1rem" }}
                      />
                    </div>

                    <div class="collapse show" id="collapse4">
                      <div className="row">
                        {/* Returning Customer Rate*/}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Returning Customer Rate
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("returningCustomerRate")}
                            className="form-control"
                            placeholder="Returning Customer Rate"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.returningCustomerRate && errors.returningCustomerRate) ? "block" : "none",
                            }}
                          >
                            {errors.returningCustomerRate}
                          </span>
                        </div>

                        {/* Time to Return (Months) */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Time to Return (Months)
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("timeToReturn")}
                            className="form-control"
                            placeholder="Time to Return (Months)"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.timeToReturn && errors.timeToReturn) ? "block" : "none",
                            }}
                          >
                            {errors.timeToReturn}
                          </span>
                        </div>

                        {/* Cost to Market Return % */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Cost to Market Return %
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("costToMarketReturn")}
                            className="form-control"
                            placeholder="Cost to Market Return %"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.costToMarketReturn && errors.costToMarketReturn) ? "block" : "none",
                            }}
                          >
                            {errors.costToMarketReturn}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Virality Metrics */}
                  <div className="card p-4 mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="body1">Virality Metrics</p>

                      <img
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse5"
                        src="/static/icons/down-arrow.svg"
                        alt=""
                        className="cp"
                        height={"12px"}
                        width={"12px"}
                        style={{ marginRight: "1rem" }}
                      />
                    </div>

                    <div class="collapse show" id="collapse5">
                      <div className="row">
                        {/* Referrers out of Customers % */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Referrers out of Customers %
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("referresOutOfCustomers")}
                            className="form-control"
                            placeholder="Referrers out of Customers %"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.referresOutOfCustomers && errors.referresOutOfCustomers) ? "block" : "none",
                            }}
                          >
                            {errors.referresOutOfCustomers}
                          </span>
                        </div>

                        {/* Invitees per Referral */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Invitees per Referral
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("inviteesPerReferral")}
                            className="form-control"
                            placeholder="Invitees per Referral"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.inviteesPerReferral && errors.inviteesPerReferral) ? "block" : "none",
                            }}
                          >
                            {errors.inviteesPerReferral}
                          </span>
                        </div>

                        {/* Invitees Conversion Rate */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Invitees Conversion Rate
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("inviteesConversionRate")}
                            className="form-control"
                            placeholder="Invitees Conversion Rate"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.inviteesConversionRate && errors.inviteesConversionRate) ? "block" : "none",
                            }}
                          >
                            {errors.inviteesConversionRate}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Admin */}
                  <div className="card p-4 mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="body1">Admin</p>

                      <img
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse6"
                        src="/static/icons/down-arrow.svg"
                        alt=""
                        className="cp"
                        height={"12px"}
                        width={"12px"}
                        style={{ marginRight: "1rem" }}
                      />
                    </div>

                    <div class="collapse show" id="collapse6">
                      <div className="row">
                        {/* Refund Rate % */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Refund Rate %
                          </label>
                          <input type="number" {...getFieldProps("refundRate")} className="form-control" placeholder="Refund Rate %" />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.refundRate && errors.refundRate) ? "block" : "none",
                            }}
                          >
                            {errors.refundRate}
                          </span>
                        </div>

                        {/* Fixed Loss per Refund (exc Product Cost) */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Fixed Loss per Refund (exc Product Cost)
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("fixedLossPerRefund")}
                            className="form-control"
                            placeholder="Fixed Loss per Refund (exc Product Cost)"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.fixedLossPerRefund && errors.fixedLossPerRefund) ? "block" : "none",
                            }}
                          >
                            {errors.fixedLossPerRefund}
                          </span>
                        </div>

                        {/* Payment Processor Fees */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Payment Processor Fees
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("paymentProcessorFees")}
                            className="form-control"
                            placeholder="Payment Processor Fees"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.paymentProcessorFees && errors.paymentProcessorFees) ? "block" : "none",
                            }}
                          >
                            {errors.paymentProcessorFees}
                          </span>
                        </div>

                        {/* Merchant Account Fees */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Merchant Account Fees
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("merchantAccountFees")}
                            className="form-control"
                            placeholder="Merchant Account Fees"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.merchantAccountFees && errors.merchantAccountFees) ? "block" : "none",
                            }}
                          >
                            {errors.merchantAccountFees}
                          </span>
                        </div>

                        {/* Fixed Costs per Month */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Fixed Costs per Month
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("fixedCostPerMonth")}
                            className="form-control"
                            placeholder="Fixed Costs per Month"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.fixedCostPerMonth && errors.fixedCostPerMonth) ? "block" : "none",
                            }}
                          >
                            {errors.fixedCostPerMonth}
                          </span>
                        </div>

                        {/* Fixed Costs Increase per 100 Customers per Month */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Fixed Costs Increase per 100 Customers per Month
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("fixedCostIncreasePerHundredCustomers")}
                            className="form-control"
                            placeholder="Fixed Costs Increase per 100 Customers per Month"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(
                                touched.fixedCostIncreasePerHundredCustomers && errors.fixedCostIncreasePerHundredCustomers
                              )
                                ? "block"
                                : "none",
                            }}
                          >
                            {errors.fixedCostIncreasePerHundredCustomers}
                          </span>
                        </div>

                        {/* Upfront Investment Costs */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Upfront Investment Costs
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("upFrontInvestmentCost")}
                            className="form-control"
                            placeholder="Upfront Investment Costs"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.upFrontInvestmentCost && errors.upFrontInvestmentCost) ? "block" : "none",
                            }}
                          >
                            {errors.upFrontInvestmentCost}
                          </span>
                        </div>

                        {/* Debt */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Debt
                          </label>
                          <input type="number" {...getFieldProps("debt")} className="form-control" placeholder="Debt" />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.debt && errors.debt) ? "block" : "none",
                            }}
                          >
                            {errors.debt}
                          </span>
                        </div>

                        {/* Debt Interest Rate % (Annual) */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Debt Interest Rate % (Annual)
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("debtInterestRate")}
                            className="form-control"
                            placeholder="Debt Interest Rate % (Annual)"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.debtInterestRate && errors.debtInterestRate) ? "block" : "none",
                            }}
                          >
                            {errors.debtInterestRate}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Valuations */}
                  <div className="card p-4 mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="body1">Valuations</p>

                      <img
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse7"
                        src="/static/icons/down-arrow.svg"
                        alt=""
                        className="cp"
                        height={"12px"}
                        width={"12px"}
                        style={{ marginRight: "1rem" }}
                      />
                    </div>

                    <div class="collapse show" id="collapse7">
                      <div className="row">
                        {/* Tax Rate % */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Tax Rate %
                          </label>
                          <input type="number" {...getFieldProps("taxRate")} className="form-control" placeholder="Tax Rate %" />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.taxRate && errors.taxRate) ? "block" : "none",
                            }}
                          >
                            {errors.taxRate}
                          </span>
                        </div>

                        {/* Number of Shares */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Number of Shares
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("numberOfShares")}
                            className="form-control"
                            placeholder="Number of Shares"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.numberOfShares && errors.numberOfShares) ? "block" : "none",
                            }}
                          >
                            {errors.numberOfShares}
                          </span>
                        </div>

                        {/* Projection Period (Days) */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Projection Period (Days)
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("projectionPeriod")}
                            className="form-control"
                            placeholder="Projection Period (Days)"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.projectionPeriod && errors.projectionPeriod) ? "block" : "none",
                            }}
                          >
                            {errors.projectionPeriod}
                          </span>
                        </div>

                        {/* Discount Rate % */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Discount Rate %
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("discountRate")}
                            className="form-control"
                            placeholder="Discount Rate %"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.discountRate && errors.discountRate) ? "block" : "none",
                            }}
                          >
                            {errors.discountRate}
                          </span>
                        </div>

                        {/* Perpetual Growth Rate % */}
                        <div className="col-4 mt-3" style={DCFResponse ? { width: "100%" } : { width: "33.33%" }}>
                          <label htmlFor="" className="form-label">
                            Perpetual Growth Rate %
                          </label>
                          <input
                            type="number"
                            {...getFieldProps("perpetualGrowthRate")}
                            className="form-control"
                            placeholder="Perpetual Growth Rate %"
                          />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.perpetualGrowthRate && errors.perpetualGrowthRate) ? "block" : "none",
                            }}
                          >
                            {errors.perpetualGrowthRate}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
          {/* )} */}

          {/* ---------- Output ---------- */}
          <div style={DCFResponse ? { marginLeft: "30px", width: "50%", marginTop: "40px" } : { marginLeft: "0px" }}>
            {DCFResponse && (
              <div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="col">
                    <div className="card" style={{ padding: "15px" }}>
                      <p className="mb-1">Customer Acquisition Cost</p>
                      <p className="body3">
                        {/* {formatNumber(DCFResponse &&
                    parseFloat(DCFResponse.customerAcquisitionCost?.toFixed(2)).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }), currency)} */}

                        {DCFResponse
                          ? `${
                              currencyValue === "USD - United States dollar"
                                ? "$"
                                : currencyValue === "GBP - Pound sterling"
                                ? "£"
                                : "₹"
                            }${parseFloat(DCFResponse.customerAcquisitionCost?.toFixed(2)).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`
                          : 0}
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card" style={{ padding: "15px" }}>
                      <p className="mb-1">Lifetime Value per Customer</p>
                      <p className="body3">
                        {/* {formatNumber(DCFResponse &&
                    parseFloat(DCFResponse.lifetimeValuePerCustomer?.toFixed(2)).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }), currency)} */}
                        {DCFResponse
                          ? `${
                              currencyValue === "USD - United States dollar"
                                ? "$"
                                : currencyValue === "GBP - Pound sterling"
                                ? "£"
                                : "₹"
                            }${parseFloat(DCFResponse.lifetimeValuePerCustomer?.toFixed(2)).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="col">
                    <div className="card" style={{ padding: "15px" }}>
                      <p className="mb-1">Gross Profit per Customer before Ads</p>
                      <p className="body3">
                        {/* {formatNumber(DCFResponse &&
                    parseFloat(DCFResponse.grossProfPerCustBefAds?.toFixed(2)).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }), currency)} */}
                        {DCFResponse
                          ? `${
                              currencyValue === "USD - United States dollar"
                                ? "$"
                                : currencyValue === "GBP - Pound sterling"
                                ? "£"
                                : "₹"
                            }${parseFloat(DCFResponse.grossProfPerCustBefAds[values?.targetDate]?.toFixed(2)).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}`
                          : 0}
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card" style={{ padding: "15px" }}>
                      <p className="mb-1">Cash Below $0 Month</p>
                      <p className="body3">{DCFResponse && DCFResponse?.cashBelowZeroMonth}</p>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="col">
                    <div className="card" style={{ padding: "15px" }}>
                      <p className="mb-1">Enterprise Value</p>
                      <p className="body3">
                        {/* {formatNumber(DCFResponse &&
                    parseFloat(DCFResponse.equityValueTotal?.toFixed(2)).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }), currency)} */}
                        {DCFResponse
                          ? `${
                              currencyValue === "USD - United States dollar"
                                ? "$"
                                : currencyValue === "GBP - Pound sterling"
                                ? "£"
                                : "₹"
                            }${parseFloat(DCFResponse.equityValueTotal?.toFixed(2)).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`
                          : 0}
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card" style={{ padding: "15px" }}>
                      <p className="mb-1">Time for Gross Profitability</p>
                      <p className="body3">{DCFResponse && DCFResponse?.timeForGrossProfitablity}</p>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="col">
                    <div className="card" style={{ padding: "15px" }}>
                      <p className="mb-1">Cash Payback Period</p>
                      <p className="body3">{DCFResponse && DCFResponse?.finalCashPayBackPeriodVal}</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card" style={{ padding: "15px" }}>
                      <p className="mb-1">Cash ROI compared to Day 1</p>
                      <p className="body3">
                        {DCFResponse &&
                          parseFloat(DCFResponse.finalCashROICompToDay?.toFixed(2)).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* all v/s Time */}
                  <div className="col" style={{ marginBottom: "20px" }}>
                    <div className="card p-4">
                      <p className="body3 text-center mb-2">
                        Revenue , Earnings , Cash , Enterprise Value , Total Customers , <br /> Cash ROI , LPVC , GPCB v/s Time
                      </p>
                      <Line
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                        }}
                        data={{
                          labels: Array.from(Array(+values?.projectionPeriod)).map((month, i) => `Month ${i + 1}`),
                          datasets: [
                            // {
                            //   label: "Total Revenue (including refunds)",
                            //   data: DCFResponse.map((month) => month.total_revenue),
                            //   fill: false,
                            //   backgroundColor: "green",
                            //   borderColor: "green",
                            // },
                            {
                              label: "Total Revenue (including refunds)",
                              data: DCFResponse.totalGrossRevValRes,
                              fill: false,
                              backgroundColor: "#1F77B4",
                              borderColor: "#1F77B4",
                            },
                            {
                              label: "Earnings",
                              data: DCFResponse.earBefIntTaxArr,
                              fill: false,
                              backgroundColor: "#FF7F0E",
                              borderColor: "#FF7F0E",
                            },
                            {
                              label: "Cash",
                              data: DCFResponse.cashInBnkArr,
                              fill: false,
                              backgroundColor: "#2CA02C",
                              borderColor: "#2CA02C",
                            },
                            {
                              label: "Enterprise Value",
                              data: DCFResponse.equityValArr,
                              fill: false,
                              backgroundColor: "#D62728",
                              borderColor: "#D62728",
                            },
                            {
                              label: "Total Customers",
                              data: DCFResponse.totalCustomerBaseVal,
                              fill: false,
                              backgroundColor: "#9467BD",
                              borderColor: "#9467BD",
                            },
                            {
                              label: "Cash ROI",
                              data: DCFResponse.cashROIformattedArr,
                              fill: false,
                              backgroundColor: "#8C564B",
                              borderColor: "#8C564B",
                            },
                            {
                              label: "Lifetime Value per Customer",
                              data: DCFResponse.ltvArray,
                              fill: false,
                              backgroundColor: "#E377C2",
                              borderColor: "#E377C2",
                            },
                            {
                              label: "Gross Profit per Customer before Ads",
                              data: DCFResponse.gpcArray,
                              fill: false,
                              backgroundColor: "#7F7F7F",
                              borderColor: "#7F7F7F",
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>

                  {/* Enterprise Value vs time */}
                  {/* <div className="col" style={{ marginBottom: "20px" }}>
                    <div className="card p-4">
                      <p className="body3 text-center mb-2">Enterprise Value v/s Time</p>
                      <Line
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                  label: "fggggg",
                                },
                              },
                            ],
                          },
                        }}
                        data={{
                          labels: Array.from(Array(+values?.projectionPeriod)).map((month, i) => `Month ${i + 1}`),
                          datasets: [
                            {
                              label: "Enterprise Value",
                              data: DCFResponse.equityValArr,
                              fill: false,
                              backgroundColor: "green",
                              borderColor: "green",
                            },
                          ],
                        }}
                      />
                      <p className="body3 text-center mb-2">Enterprise Value</p>
                    </div>
                  </div> */}

                  {/* Total Customers v/s Time */}
                  {/* <div className="col" style={{ marginBottom: "20px" }}>
                    <div className="card p-4">
                      <p className="body3 text-center mb-2">Total Customers v/s Time</p>
                      <Line
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                        }}
                        data={{
                          labels: Array.from(Array(+values?.projectionPeriod)).map((month, i) => `Month ${i + 1}`),
                          datasets: [
                            {
                              label: "Total Customers",
                              data: DCFResponse.totalCustomerBaseVal,
                              fill: false,
                              backgroundColor: "green",
                              borderColor: "green",
                            },
                          ],
                        }}
                      />
                    </div>
                  </div> */}

                  {/* Total Revenue v/s time */}
                  {/* <div className="col" style={{marginBottom: '20px'}}>
              <div className="card p-4">
                <p className="body3 text-center mb-2">Total Revenue v/s Time</p>
                <Line
                  data={{
                    labels: Array.from(Array(+values?.projectionPeriod)).map((month, i) => `Month ${i + 1}`),
                    datasets: [
                      {
                        label: "Total Revenue (including refunds)",
                        data: DCFResponse.totalGrossRevValRes,
                        fill: false,
                        backgroundColor: "green",
                        borderColor: "green",
                      },
                      // {
                      //   label: "Total Gross Profit",
                      //   data: DCFResponse.map((month) => month.total_gross_profit_after_adspend),
                      //   fill: false,
                      //   backgroundColor: "orange",
                      //   borderColor: "orange",
                      // },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                  }}
                />
              </div>
            </div> */}

                  {/* Cash ROI v/s Time  */}
                  {/* <div className="col" style={{ marginBottom: "20px" }}>
                    <div className="card p-4">
                      <p className="body3 text-center mb-2">Cash ROI v/s Time</p>
                      <Line
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                        }}
                        data={{
                          labels: Array.from(Array(+values?.projectionPeriod)).map((month, i) => `Month ${i + 1}`),
                          datasets: [
                            {
                              label: "Cash ROI",
                              data: DCFResponse.cashROIformattedArr,
                              fill: false,
                              backgroundColor: "green",
                              borderColor: "green",
                            },
                          ],
                        }}
                      />
                    </div>
                  </div> */}

                  {/* LPVC, GPCB v/s Time  */}
                  {/* <div className="col" style={{ marginBottom: "20px" }}>
                    <div className="card p-4">
                      <p className="body3 text-center mb-2">LTV, Gross Profit per Customer before Ads v/s Time</p>
                      <Line
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                        }}
                        data={{
                          labels: Array.from(Array(+values?.projectionPeriod)).map((month, i) => `Month ${i + 1}`),
                          datasets: [
                            {
                              label: "Lifetime Value per Customer",
                              data: DCFResponse.ltvArray,
                              fill: false,
                              backgroundColor: "green",
                              borderColor: "green",
                            },
                            {
                              label: "Gross Profit per Customer before Ads",
                              data: DCFResponse.gpcArray,
                              fill: false,
                              backgroundColor: "blue",
                              borderColor: "blue",
                            },
                          ],
                        }}
                      />
                    </div>
                  </div> */}
                </div>

                <div className="row g-5 mt-3">
                  {/* Input */}
                  <div className="col-6">
                    <p className="body2 mb-2">Input</p>

                    <div className="mb-3">
                      <p className="body2 mb-0">Starting State</p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />
                      {/* <div className="d-flex justify-content-between">
                  <p className="mb-1">Starting Date</p>
                  <p className="mb-1">{startingDate}</p>
                </div>  //C*/}
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Cash in Bank</p>
                        <p className="mb-1">{values?.cashInBank}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Initial Number of Customers</p>
                        <p className="mb-1">{values?.numberOfCustomers}</p>
                      </div>
                    </div>

                    <div className="mb-3">
                      <p className="body2 mb-0">Financial Data</p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Average Order Value</p>
                        <p className="mb-1">{values?.avgOrderValue}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Realisation Rate</p>
                        <p className="mb-1">{values?.realisationRate}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Units/order</p>
                        <p className="mb-1">{values?.unitsOrder}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Blended COGS %</p>
                        <p className="mb-1">{values?.blendedCogs}</p>
                      </div>
                    </div>

                    <div className="mb-3">
                      <p className="body2 mb-0">Marketing Metrics</p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />

                      <p className="body2 mb-0 mt-2">Outbound</p>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Outbound Salary</p>
                        <p className="mb-1">{values?.outboundSalary}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Number of Contacts per Month per SDR</p>
                        <p className="mb-1">{values?.numberOfContactsPerSdr}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Number of SDRs</p>
                        <p className="mb-1">{values?.numberOfSDR}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">
                          Contact to Lead Conversion <br></br> Rate
                        </p>
                        <p className="mb-1">{values?.contactToLeadConversionRate}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">
                          Lead to Customer Conversion <br></br>Rate (Outbound)
                        </p>
                        <p className="mb-1">{values?.leadToCustomerConversionRate}</p>
                      </div>

                      <p className="body2 mb-0 mt-2">Inbound</p>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Monthly Ad Spend</p>
                        <p className="mb-1">{values?.monthlyAdSpend}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">cpm</p>
                        <p className="mb-1">{values?.cpm}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Click Through Rate</p>
                        <p className="mb-1">{values?.clickThroughRate}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Landing Page View Rate</p>
                        <p className="mb-1">{values?.landingPageView}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Lead Generation Rate</p>
                        <p className="mb-1">{values?.leadGenerationRate}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Conversion Rate</p>
                        <p className="mb-1">{values?.conversionRate}</p>
                      </div>

                      <p className="body2 mb-0 mt-2">Organic</p>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Organic Views per Month</p>
                        <p className="mb-1">{values?.organicViewsPerMonth}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Organic View to Lead Conversion Rate</p>
                        <p className="mb-1">{values?.organicViewsLeadToConversionRate}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Lead to Customer Conversion Rate (Organic)</p>
                        <p className="mb-1">{values?.organicleadToCustomerConversionRate}</p>
                      </div>
                    </div>

                    <div className="mb-3">
                      <p className="body2 mb-0">Retention Metrics</p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Returning Customer Rate</p>
                        <p className="mb-1">{values?.returningCustomerRate}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Time to Return (Months)</p>
                        <p className="mb-1">{values?.timeToReturn}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Cost to Market Return %</p>
                        <p className="mb-1">{values?.costToMarketReturn}</p>
                      </div>
                    </div>

                    <div className="mb-3">
                      <p className="body2 mb-0">Virality Metrics</p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Referrers out of Customers %</p>
                        <p className="mb-1">{values?.referresOutOfCustomers}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Invitees per Referral</p>
                        <p className="mb-1">{values?.inviteesPerReferral}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Invitees Conversion Rate</p>
                        <p className="mb-1">{values?.inviteesConversionRate}</p>
                      </div>
                    </div>

                    <div className="mb-3">
                      <p className="body2 mb-0">Admin</p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Refund Rate %</p>
                        <p className="mb-1">{values?.refundRate}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Fixed Loss per Refund (exc Product Cost)</p>
                        <p className="mb-1">{values?.fixedLossPerRefund}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Payment Processor Fees</p>
                        <p className="mb-1">{values?.paymentProcessorFees}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Merchant Account Fees</p>
                        <p className="mb-1">{values?.merchantAccountFees}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Fixed Costs per Month</p>
                        <p className="mb-1">{values?.fixedCostPerMonth}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Fixed Costs Increase per 100 Customers per Month</p>
                        <p className="mb-1">{values?.fixedCostIncreasePerHundredCustomers}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Upfront Investment Costs</p>
                        <p className="mb-1">{values?.upFrontInvestmentCost}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Debt</p>
                        <p className="mb-1">{values?.debt}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Debt Interest Rate % (Annual)</p>
                        <p className="mb-1">{values?.debtInterestRate}</p>
                      </div>
                    </div>

                    <div className="mb-3">
                      <p className="body2 mb-0">Valuations</p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Tax Rate %</p>
                        <p className="mb-1">{values?.taxRate}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Number of Shares</p>
                        <p className="mb-1">{values?.numberOfShares}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Projection Period (Months)</p>
                        <p className="mb-1">{values?.projectionPeriod}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Discount Rate %</p>
                        <p className="mb-1">{values?.discountRate}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Perpetual Growth Rate %</p>
                        <p className="mb-1">{values?.perpetualGrowthRate}</p>
                      </div>
                    </div>
                  </div>

                  {/* Output */}
                  <div className="col-6">
                    <p className="body2 mb-2">Output</p>

                    <div className="mb-3">
                      <p className="body2 mb-0">COGS per Order</p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Realised Revenue</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.realisedRevenue[values?.targetDate] : 0)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">COGS per Order</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.cogsPerOrder[values?.targetDate] : 0)}
                        </p>
                      </div>
                    </div>

                    <div className="mb-3">
                      <p className="body2 mb-0">Marketing Metrics</p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />

                      <p className="body2 mb-0 mt-2">Outbound</p>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">SDR Salaries</p>
                        <p className="mb-1">{formatNumber(DCFResponse ? DCFResponse.sdrSalaries[values?.targetDate] : 0)}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Contacts</p>
                        <p className="mb-1">{formatNumber(DCFResponse ? DCFResponse.contacts[values?.targetDate] : 0)}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Outbound Leads</p>
                        <p className="mb-1">{formatNumber(DCFResponse ? DCFResponse.outboundLeads[values?.targetDate] : 0)}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Outbound Customers</p>
                        <p className="mb-1">{formatNumber(DCFResponse ? DCFResponse.outboundCustomers[values?.targetDate] : 0)}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Outbound Revenue</p>
                        <p className="mb-1">{formatNumber(DCFResponse ? DCFResponse.outboundRevenue[values?.targetDate] : 0)}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Outbound Gross Profit</p>
                        <p className="mb-1">{formatNumber(DCFResponse ? DCFResponse.outboundGrossProfit : 0)}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">ROI</p>
                        <p className="mb-1">{formatNumber(DCFResponse ? DCFResponse.roi : 0)}</p>
                      </div>

                      <p className="body2 mb-0 mt-2">Inbound</p>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Advertising Spend</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.advertisingSpend[values?.targetDate] : 0)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Reach</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.reach?.toFixed(2)).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Link Clicks</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.linkClicks?.toFixed(2)).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Page Views</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.pageViews?.toFixed(2)).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Inbound Leads</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.inboundLeads[values?.targetDate] : 0)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Inbound Customers</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.inboundCustomers?.toFixed(2)).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Inbound Revenue</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.inboundRevenue?.toFixed(2)).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">ROAS</p>
                        <p className="mb-1">{formatNumber(DCFResponse ? DCFResponse.roas?.toFixed(2) : 0)}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Gross Profit</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.inboundGrossProfit?.toFixed(2)).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">ROI</p>
                        <p className="mb-1">
                          {formatNumber(DCFResponse ? DCFResponse.inboundROI[values?.targetDate]?.toFixed(2) : 0)}
                        </p>
                      </div>

                      <p className="body2 mb-0 mt-2">Organic</p>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Organic Leads</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.organicLeads[values?.targetDate] : 0)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Organic Customers</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.organicCustomers[values?.targetDate] : 0)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Organic Revenue</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.organicRevenue[values?.targetDate] : 0)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Organic Gross Profit</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.organicGrossProfit[values?.targetDate] : 0)}
                        </p>
                      </div>

                      <p className="body2 mb-0 mt-2">Total</p>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Marketing Spend</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.marketingSpend[values?.targetDate]).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">
                          Marketing Customers<br></br>
                          Acquired
                        </p>
                        <p className="mb-1">
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.marketingCustomersAcquired[values?.targetDate]).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Marketing Revenue</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.marketingRevenue[values?.targetDate] : 0)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Marketing Gross Profit</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.marketingGrossProfit?.toFixed(2)).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Gross Profit before Ads per Customer per Month</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.grossProfitBeforeAdspendPerCustPerMonth[values?.targetDate]?.toFixed(2))
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Gross Profit after Ads per Customer per Month</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.grossProfitAfterAdspendPerCustPerMonth[values?.targetDate]?.toFixed(2))
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Marketing ROI</p>
                        <p className="mb-1">{formatNumber(DCFResponse ? DCFResponse.marketingROI?.toFixed(2) : 0)}</p>
                      </div>
                    </div>

                    <div className="mb-3">
                      <p className="body2 mb-0">Retention Metrics</p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Total Customer Base</p>
                        <p className="mb-1">{formatNumber(DCFResponse ? DCFResponse.totalCustomerBase[values?.targetDate] : 0)}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Returning Customers</p>
                        <p className="mb-1">{formatNumber(DCFResponse ? DCFResponse.returningCustomers[values?.targetDate] : 0)}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Monthly Returning Revenue</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.monthlyReturningRevenue?.toFixed(2)).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Cost to Market Return</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.outputCostToMarketReturn?.toFixed(2)).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Refund Cost/Loss</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.refundCostLoss[values?.targetDate] : 0)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Total Refund Orders</p>
                        <p className="mb-1">{formatNumber(DCFResponse ? DCFResponse.totalRefundOrders[values?.targetDate] : 0)}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Total Refunds Loss</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.totalRefundLoss[values?.targetDate] : 0)}
                        </p>
                      </div>
                    </div>

                    <div className="mb-3">
                      <p className="body2 mb-0">Virality Metrics</p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Total Referrers</p>
                        <p className="mb-1">
                          {DCFResponse
                            ? parseFloat(DCFResponse.totalReferrers?.toFixed(0)).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Total Invitees</p>
                        <p className="mb-1">
                          {DCFResponse
                            ? parseFloat(DCFResponse.totalInvitees?.toFixed()).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0}
                        </p>
                        {/* //C */}
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Referral Customers</p>
                        <p className="mb-1">{formatNumber(DCFResponse ? truncate(DCFResponse.referralCustomers, 1) : 0)}</p>
                        {/* //C */}
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Referral Revenue</p>
                        <p className="mb-1">
                          {DCFResponse
                            ? parseFloat(DCFResponse.referralRevenue?.toFixed()).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0}
                        </p>
                        {/* //C */}
                      </div>
                    </div>

                    <div className="mb-3">
                      <p className="body2 mb-0">Total Marketing Metrics</p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Total Gross Revenue</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? parseFloat(DCFResponse.totalGrossRevenue?.toFixed(2)) : 0)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Total Gross Profit before Adspend</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.totalGrossProfitBeforeAdspend?.toFixed(2)).toLocaleString({
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Gross Profit Margin before Adspend</p>
                        <p className="mb-1">
                          {formatNumber(DCFResponse ? DCFResponse.grossMarginProfitBeforeAdspend?.toFixed(2) : 0)}%
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Total Gross Profit after Adspend</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.totalGrossProfitAfterAdspend?.toFixed(2)).toLocaleString({
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Gross Profit Margin after Adspend</p>
                        {/* <p className="mb-1">
                          {formatNumber(DCFResponse ? DCFResponse.grossMarginProfitAfterAdspend?.toFixed(2) : 0)}%
                        </p> */}
                      </div>
                    </div>

                    <div className="mb-3">
                      <p className="body2 mb-0">Admin </p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Payment Processor Fees per Order</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.paymentProcessorFeesPerOrder : 0)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Merchant Account Fees per Order</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.merchantAccFeesPerOrder : 0)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Total Payment Processor Fees</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.totalPaymentProcessorFees?.toFixed(2)).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Total Merchant Account Fees</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.totalmerchantAccFees?.toFixed(2)).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Total Fixed Costs</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.totalFixedCosts).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Upfront Investment Costs</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.upFrontInvestmentCosts : 0)}
                        </p>
                      </div>
                    </div>

                    <div className="mb-3">
                      <p className="body2 mb-0">Valuation</p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Earnings Before Interest & Tax</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.earningBeforeIntAndTax[values?.targetDate]).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Cash Taxes</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.cashTaxes).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Debt Interest Payable</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.debtInterestPayable : 0)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        {/*  */}
                        <p className="mb-1">Unlevered Free Cash Flow</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.unleveredFreeCashFlow[values?.targetDate]).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Enterprise Value</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.enterPriseValue[values?.targetDate]).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Plus: Cash</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.plusCash).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Less: Debt</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(DCFResponse ? DCFResponse.lessDebt : 0)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Enterprise Value</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.equityValue).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Enterprise Value/Share</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.equityValueShare).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Earnings After Interest & Tax</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.earningAfterIntAndTax).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Avg Terminal Value (Perpetual Growth)</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.avgTerminalValue[values?.targetDate]).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="mb-3">
                      <p className="body2 mb-0">Overall</p>
                      <hr style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }} />
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Cash In Bank</p>
                        <p className="mb-1">
                          {currencyValue === "USD - United States dollar" ? "$" : currencyValue === "GBP - Pound sterling" ? "£" : "₹"}
                          {formatNumber(
                            DCFResponse
                              ? parseFloat(DCFResponse.overAllCashInBank).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Cash ROI compared to Day 1</p>
                        <p className="mb-1">{formatNumber(DCFResponse ? DCFResponse.cashROICompToDay?.toFixed(2) : 0)}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-1">Cash Payback Period</p>
                        <p className="mb-1">{DCFResponse ? DCFResponse.cashPaybackPeriod : "False"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <CreateNewModelDialog />
      </div>
    </div>
  );
}

export default ModelInfo;
